import { Serializable } from "core/shared/serializable.model";

export class ExternalGoal extends Serializable {
    public id: string = null;
    public value: any = null;
    public sales_team_id: string = null;
    public sales_funnel_id: string = null;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    get http_data() {
        return {
            sales_team_id: this.sales_team_id,
            value: this.value
        };
    }
}
