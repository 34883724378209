import { Serializable } from "core/shared/serializable.model";
import { Profile } from "pages/account/profiles/shared/profile.model";
import { Manager } from "pages/inputs/people/shared/manager.model";
import { IPersonAvatar } from "pages/inputs/people/components/person-avatar/person.avatar.interface";
import { Person } from "pages/inputs/people/shared/person.model";
import { Realtor } from "pages/inputs/people/shared/realtor.model";
import { RegionalManager } from "pages/inputs/people/shared/regional-manager.model";
import { MenuPage } from "core/components/side-menu/menu-page";
import { MenuPages } from "core/components/side-menu/menu-pages";
import { CompanyArea } from "pages/inputs/company-areas/shared/company-area.model";

export class User extends Serializable implements IPersonAvatar {

    public id: string = null;
    public name: string = null;
    public email: string = null;
    public person: Person = null;
    public realtor: Realtor = null;
    public manager: Manager = null;
    public regional_manager: RegionalManager = null;
    public profiles: Profile[] = [];
    public image: any = null;
    public password: string = null;
    public password_confirmation: string = null;
    public authenticationType: string = null;
    public enableEmailNotifications: boolean = null;
    public companyAreas: CompanyArea[] = [];

    public pages: MenuPage[] = [];
    public managementPages: MenuPages = new MenuPages;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
        this.setPages();
    }

    get relationships() {
        return {
            person: Person,
            realtor: Realtor,
            manager: Manager,
            regional_manager: RegionalManager,
            profiles: Profile,
            companyAreas: CompanyArea
        };
    }

    get http_data() {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            profiles: this.profiles.map((profile: Profile) => profile.id)
        };
    }

    public can(type: string, category: string) {
        if (!this.profiles) {
            return false;
        }

        return this.profiles.some((profile: Profile) => profile.can(type, category) == true);
    }

    public cannot(type: string, category: string) {
        return !this.can(type, category);
    }

    public get accessType() {
        if (!this.profiles) {
            return null;
        }

        return this.profiles.find((profile: Profile) => profile.access_type != null)?.access_type;
    }

    get authenticatable() {
        if (this.person?.id) {
            return this.person;
        }

        if (this.manager?.id) {
            return this.manager;
        }

        if (this.realtor?.id) {
            return this.realtor;
        }

        if (this.regional_manager?.id) {
            return this.regional_manager;
        }

        return null;
    }

    get maturityColor() {
        if (this.authenticatable) {
            return this.authenticatable.maturityColor;
        }

        return 'avatar-padrao';
    }

    get firstName() {
        return this.name.split(" ")[0];
    }

    get userIsActiveDirectory() {
        return this.authenticationType === 'ad'
    }

    get authentication_color() {
        switch(this.authenticationType) {
            case 'local':
                return 'prestes-green';
            default:
                return 'primary';
        }
    }

    private setPages() {
        var type = this.accessType;

        if (type) {
            this.pages = this[type + 'Pages'];
        } else {
            this.pages = [];
        }
    }

    public get defaultPage() {
        let page = this.pages.find( page => page.default);

        if (page) {
            return page.url;
        };

        page = this.managementPages.pages.find ( page => page.default);

        if (page) {
            return page.url;
        }

        return '/welcome';
    }

    public get realtorPages() {
        return [
            new MenuPage({
                default: true,
                title: 'Dashboard',
                url: '/dashboard/realtor/' + this.realtor?.id,
                icon: 'speedometer',
            })
        ];
    }

    public get managerPages() {
        var salesTeams = this.manager?.managed_sales_teams;

        if (salesTeams && salesTeams.length) {
            return salesTeams.map( salesTeam => {
                return new MenuPage({
                    default: true,
                    title: 'Dashboard',
                    subtitle: salesTeam.name,
                    url: '/dashboard/sales-team/' + salesTeam.id,
                    icon: 'speedometer',
                    showDetails: false,
                });
            });
        }

        return [
            new MenuPage({
                default: true,
                title: 'Dashboard',
                subtitle: this.manager?.name,
                url: '/dashboard/manager/' + this.manager?.id,
                icon: 'speedometer',
                showDetails: false,
            })
        ];
    }

    public get regionalPages() {
        return [
            new MenuPage({
                default: true,
                title: 'Dashboard',
                url: '/dashboard/regional/' + this.regional_manager?.id,
                icon: 'speedometer',
                showDetails: false,
            }),
            new MenuPage({
                title: 'Estoque',
                url: "/products",
                params: { mode: 'grid' },
                icon: 'business',
                permission: 'products',
            }),
            new MenuPage({
                default: false,
                title: 'Desdobramentos',
                url: '/breakdowns',
                icon: 'bar-chart',
                showDetails: false,
            })
        ];
    }

    public get adminPages() {
        return [
            new MenuPage({
                default: true,
                title: 'Dashboard',
                url: '/dashboard/admin',
                icon: 'speedometer',
                showDetails: false,
            }),
            new MenuPage({
                default: false,
                title: 'Resumo',
                url: '/summary',
                icon: 'reader',
                showDetails: false,
            }),
            new MenuPage({
                default: false,
                title: 'Estoque',
                url: "/products",
                params: { mode: 'grid' },
                icon: 'business',
                permission: 'products',
            }),
            new MenuPage({
                default: false,
                title: 'Desdobramentos',
                url: '/breakdowns',
                icon: 'bar-chart',
                showDetails: false,
            }),
        ];
    }

    public get hasCompanyArea() {
        return this.companyAreas.length > 0;
    }
}
