import { Serializable } from "core/shared/serializable.model";
import { ApprovalFlow } from "pages/approvals/approval-flows/shared/approval-flow.model";
import { Company } from "pages/inputs/companies/shared/company.model";

export class CostCenter extends Serializable {

    public name: string = null;
    public siengeId: number = null;
    public company: Company = null;
    public active: boolean = null;
    public approvalFlows: ApprovalFlow[] = [];
    public appendedApprovalFlows: ApprovalFlow[] = [];

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return {
            company: Company,
            approvalFlows: ApprovalFlow,
            appendedApprovalFlows: ApprovalFlow,
        };
    }

    public get label() {
        return this.siengeId + ' - ' + this.name;
    }

    public get http_data() {
        return {
            active:        this.active,
            approvalFlows: this.appendedApprovalFlows.map(
                (approvalFlow: ApprovalFlow) => approvalFlow.approversHttpData
            )
        };
    }
}