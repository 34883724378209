import { Serializable } from 'core/shared/serializable.model';

export class CommissionInstallmentTransferStage extends Serializable {

    public stageName: string = null;
    public installmentNumber: number = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get httpData() {
        return {
            stageName:         this.stageName,
            installmentNumber: this.installmentNumber,
        };
    }
}
