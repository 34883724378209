import { NgModule } from '@angular/core';
import { UserCanDirective } from 'pages/account/permissions/shared/user-can.directive';
import { UserCannotDirective } from 'pages/account/permissions/shared/user-cannot.directive';

@NgModule({
    exports: [
        UserCanDirective,
        UserCannotDirective
    ],
    declarations: [
        UserCanDirective,
        UserCannotDirective
    ]
})
export class PermissionModule { }
