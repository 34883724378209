import { Serializable } from 'core/shared/serializable.model';
import { IptuPaymentInstallment } from './iptu-payment-installment.model';

export class IptuPayment extends Serializable {

    public glpiTicketId: number = null;
    public glpiCancellationTicketId: number = null;
    public glpiTicketStatus: string = null;
    public siengeBillId: number = null;
    public installments: IptuPaymentInstallment[] = [];
   
    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }
    
    public get relationships() {
        return {
            installments: IptuPaymentInstallment
        };
    }

    public get glpiTicketLink(): string {
        return 'https://helpdesk.prestes.com/front/ticket.form.php?id=' + this.glpiTicketId;
    }

    public get hasCancellationTicket() {
        return this.installments.some(
            (installment: IptuPaymentInstallment) =>
                 installment.glpiCancellationTicketId ? true : false
        );
    }
}
