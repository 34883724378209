import { Serializable } from 'core/shared/serializable.model';
import { SalesGoal } from 'pages/pv/goals/shared/sales-goal.model';

export class SalesFunnelStage extends Serializable {
    public id: string = null;
    public name: string = null;
    public goal: SalesGoal = null;
    public order: number = null;
    public next_stage: string = null;
    public internal_conversion_weight: number = null;
    public external_conversion_weight: number = null;
    public discard: number = null;

    public pivot: any = null;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    // get relationships() {
    //     return {
    //         sales_funnel: SalesFunnel
    //     };
    // }
}
