import { SurveyService } from 'pages/pv/surveys/shared/survey.service';
import { SurveySection } from 'pages/pv/surveys/shared/survey-section.model';
import { SurveyCategory } from 'pages/pv/surveys/shared/survey-category.model';
import { Serializable } from 'core/shared/serializable.model';

export class Survey extends Serializable {
    public id: string = null;
    public description: string = null;
    public survey_category: SurveyCategory = null;
    public active: boolean = true;
    public survey_sections: Array<SurveySection> = [];

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public addSection() {
        this.survey_sections.push(new SurveySection({ order: this.survey_sections.length }));
        const survey = new SurveySection();
        survey.addQuestion();

        return this.survey_sections.length;
    }

    public removeSection(index: number) {
        this.survey_sections.splice(index, 1);
    }
    
    public get relationships() {
        return {
            survey_category: SurveyCategory,
            survey_sections: SurveySection
        };
    }

    public get http_data() {
        return {
            id: this.id,
            description: this.description,
            survey_category_id: this.survey_category?.id,
            active: this.active,
            survey_sections: this.survey_sections.map(surveySection => surveySection.http_data),
        };
    }
}
