import { Serializable } from "core/shared/serializable.model";
import { CheckupQuestion } from "pages/pv/checkups/shared/checkup-question.model";

export class CheckupSection extends Serializable {
    public id: string = null;

    public name: string = null;

    public order: number = null;

    public checkup_questions: Array<CheckupQuestion> = [];
    public checked = 0;

    public score: number = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    get relationships() {
        return {
            checkup_questions: CheckupQuestion
        };
    }

    addCheck() {
        this.checked += 1;
    }

    minusCheck() {
        this.checked -= 1;
    }


    get verifyCheck() {
        return (this.checked == this.checkup_questions.length);
    }

    get http_data() {
        return {
            id: this.id,
            name: this.name,
            order: this.order,
            checkup_questions: this.checkup_questions.map(checkup_question => checkup_question.http_data),
        };
    }

    get scoreRating() {
        if (this.score > 7) {
            return "above_average";
        } else if (this.score >= 6 && this.score <= 7) {
            return "average";
        } else if (this.score < 6) {
            return "below_average";
        }
    }

    get color() {
        if (this.total_score > 7) {
            return "success";
        } else if (this.total_score >= 6 && this.total_score <= 7) {
            return "warning";
        }
        else if (this.total_score < 6) {
            return "danger";
        }
    }

    get scoreColor() {
        switch(this.scoreRating) {
            case 'above_average':
                return 'success';
            case 'average':
                return 'warning';
            case 'below_average':
                return 'danger';
            default:
                return 'medium';
        }
    }

    get scoreHexColor() {
        switch(this.scoreRating) {
            case 'above_average':
                return '#3880ff';
            case 'average':
                return '#ffc409';
            case 'below_average':
                return '#eb445a';
            default:
                return 'medium';
        }
    }

    get total_score() {
        return this.checkup_questions.map(
            checkup_question => (checkup_question.score === null || isNaN(checkup_question.score)) ? 0 : checkup_question.score
        ).reduce(
            (current, previous) =>
                (parseFloat(current + '') + parseFloat(previous + ''))
            , 0);
    }

    get total_points() {
        return this.checkup_questions.map(
            checkup_question => checkup_question.total_points
        ).reduce(
            (current, previous) => (parseFloat(current + '') + parseFloat(previous + ''))
            , 0);
    }

    get valid() {
        return this.checkup_questions.every(
            question => question.valid
        );
    }

    get first_invalid_question() {
        return this.checkup_questions.find(
            question => !question.valid
        );
    }

    get short_name() {
        return this.name.split(' ')[0];
    }
}
