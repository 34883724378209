import { Serializable } from "core/shared/serializable.model";

export class CommissionRuleCondition extends Serializable {

    public minValue: number = null;
    public maxValue: number = null;
    public installmentValue: number = null;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get http_data() {
        return {
            id:       this.id ?? null,
            minValue: this.minValue,
            maxValue: this.maxValue,
            installmentValue: this.installmentValue,
        };
    }
}