import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localePt from '@angular/common/locales/pt';
import { CreateModal } from 'pages/misc/feedback/create/create.modal';
import { PersonProfileModule } from 'pages/inputs/people/components/person-profile/person-profile.module';
import { PersonAvatarModule } from 'pages/inputs/people/components/person-avatar/person-avatar.module';
import { WelcomeModule } from 'pages/account/auth/welcome/welcome.module';
import { PermissionModule } from 'pages/account/permissions/shared/permission.module';
import { ReportModule } from 'pages/pv/reports/shared/report.module';
import { ThemeSwitcherModule } from 'core/components/theme-switcher/theme-switcher.module';
import { ErrorModule } from 'pages/misc/errors/shared/error.module';
import { ScrollbarThemeModule } from 'core/directives/scrollbar-theme.directive';
import { PersonProfile } from 'pages/inputs/people/components/person-profile/person-profile';
import { AuthInterceptor } from 'pages/account/auth/shared/auth.interceptor';
import { DocPipe } from 'core/pipes/doc';
import { ToastService } from 'core/shared/toast.service';
import { BaseModule } from 'core/shared/base.module';
import './core/shared/custom-methods';

registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
        CreateModal
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        IonicModule,
        PersonProfileModule,
        ReactiveFormsModule,
        PersonAvatarModule,
        WelcomeModule,
        PermissionModule,
        ReportModule,
        ThemeSwitcherModule,
        ErrorModule,
        ScrollbarThemeModule,
        BaseModule
    ],
    exports: [
        PersonProfile,
        CommonModule,
        FormsModule,
        IonicModule,
    ],
    providers: [
        DocPipe,
        ToastService,
        {
            provide: LOCALE_ID, useValue: 'pt-BR'
        },
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [
        AppComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]

})



export class AppModule {}
