import { Serializable } from 'core/shared/serializable.model';

export class SurveyQuestion extends Serializable {
    public id:   string = null;

    public statement: string = null;

    public total_points: number = null;

    public order: number = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get http_data() {
        return {
            id:           this.id,
            statement:    this.statement,
            total_points: this.total_points,
            order:        this.order,
        };
    }
}
