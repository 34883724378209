import { Serializable } from "core/shared/serializable.model";
import { CostCenter } from "pages/inputs/cost-centers/shared/cost-center.model";

export class Company extends Serializable {

    public name: string = null;
    public cnpj: string = null;
    public siengeId: number = null;
    public administrative: boolean = null;
    public active: boolean = false;

    public costCenters: CostCenter[] = [];

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return {
            costCenters: CostCenter
        };
    }

    public get label() {
        return this.siengeId + ' - ' + this.name;
    }
    
    public get type() {
        if (this.administrative) {
            return "Administrativo";
        }

        return "Obra";
    }

    public get activeCostCenters() {
        return this.costCenters.filter(costCenter => costCenter.active);
    }

    public get http_data() {
        return {
            administrative: this.administrative,
            active:         this.active,
        };
    }
}