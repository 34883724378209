import { Serializable } from "core/shared/serializable.model";

export class PreservationState extends Serializable {

    public id: string = null;
    public name: string = null;
    public description: string = null;
    public order: number = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get http_data() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            order: this.order
        }
    }

}
