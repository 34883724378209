import { ActionChecklistItem } from "pages/pv/corrective-actions/shared/action-checklist-item.model";

export class TrainingInfo {
    public neededTraining: number = 0;
    public plannedTraining: number = 0;
    public completedTraining: number = 0;
    public actionChecklistItems: ActionChecklistItem[] = [];

    constructor(data: any = null) {
        if (!data) {
            return;
        }

        this.neededTraining    = data.neededTraining    ? data.neededTraining    : 0;
        this.plannedTraining   = data.plannedTraining   ? data.plannedTraining   : 0;
        this.completedTraining = data.completedTraining ? data.completedTraining : 0;
        this.setActionChecklistItems(data.actionChecklistItems);
    }

    private setActionChecklistItems(actionChecklistItems: Array<any> = []) {
        if (actionChecklistItems) {
            this.actionChecklistItems = actionChecklistItems.map ( 
                item => new ActionChecklistItem(item)
            );
        }
    }
}