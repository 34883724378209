import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeSwitcherComponent } from 'core/components/theme-switcher/theme-switcher.component';
import { PermissionModule } from 'pages/account/permissions/shared/permission.module';


@NgModule({
	declarations: [
		ThemeSwitcherComponent
	],
	imports: [
		CommonModule,
		PermissionModule
	],
	exports: [
		ThemeSwitcherComponent
	]
})
export class ThemeSwitcherModule { }
