import { Profile } from 'pages/account/profiles/shared/profile.model';
import { Person } from 'pages/inputs/people/shared/person.model';
import { Serializable } from 'core/shared/serializable.model';


export class Position extends Serializable {

    public id:   string          = null;
    public name: string          = null;
    public people: Array<Person> = [];
    public profile: Profile      = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    get relationships() {
        return {
            people: Person,
            profile: Profile,
        };
    }

    get http_data() {
        return {
            name: this.name,
            profile_id: this.profile_id,
        };
    }

    get profile_id() {
        return this.profile?.id;
    }
}
