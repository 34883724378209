import { Serializable } from "core/shared/serializable.model";
import { PermissionCategory } from "pages/account/permissions/shared/permission-category.model";

export class Permission extends Serializable {
    
    public id:   string = null;
    public name: string = null;
    public type: string = null;
    public allow: boolean = false;
    public permission_category: PermissionCategory = null;
    public pivot: any = null;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    get relationships() {
        return {
            permission_category: PermissionCategory
        }
    }

    has(type: string, category: string) {
        return (
            this.type == type && 
            this.permission_category.type == category
        );
    }
}   