import { DateTime } from 'luxon';
import { Lead } from 'pages/pv/leads/shared/lead.model';
import { Serializable } from 'core/shared/serializable.model';
import { Product } from 'pages/inputs/products/shared/product.model';
import { ExternalStage } from 'pages/pv/sales-funnels/shared/external-stage.model';
import { SalesFunnelStage } from 'pages/pv/sales-funnels/shared/sales-funnel-stage.model';
import { Realtor } from 'pages/inputs/people/shared/realtor.model';
import { SalesTeam } from 'pages/pv/sales-teams/shared/sales-team.model';

export class LeadSnapshot extends Serializable {

    public id: string = null;
    public date: string = null;
    public order: number = null;
    public action: string = null;
    public change: string = null;
    public change_target_id: number = null;
    public change_type: string = null;
    public snapshot_type: string = null;
    public created_at: string = null;
    public external_id: number = null;
    public ignored_change: boolean = null;
    public manually_ignored_change: boolean = null;

    public lead: Lead = null;
    public person: Realtor = null;
    public sales_team: SalesTeam = null;
    public next_lead_snapshot: LeadSnapshot = null;
    public previous_lead_snapshot: LeadSnapshot = null;
    public sales_funnel_stage: SalesFunnelStage = null;
    public external_stage: ExternalStage = null;
    public products: Array<Product> = [];
    public reservations: Array<any> = [];
    public pre_registrations: Array<any> = [];

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get relationships(): Object {
        return {
            lead: Lead,
            person: Realtor,
            sales_team: SalesTeam,
            next_lead_snapshot: LeadSnapshot,
            previous_lead_snapshot: LeadSnapshot,
            sales_funnel_stage: SalesFunnelStage,
            external_stage: ExternalStage,
            products: Product
        };
    }

    public get hasCheckup() {
        if (this.lead) {
            return this.lead.checkup;
        }

        return false;
    }


    public get formattedDate() {
        return DateTime.fromFormat(this.date, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy HH:mm:ss');
    }

    public get isIgnored() {
        return (this.ignored_change || this.manually_ignored_change) === true;
    }
}
