export class PerformanceInfo {
    public equipeProdutiva: any = 0;
    public realIpc: number = 0;
    public targetIpc: number = 0;
    public realIpi: number = 0;
    public targetIpi: number = 0;
    public realIpt: number = 0;
    public targetIpt: number = 0;
    public ipg: number = 0;
    public turnover: number = 0;

    constructor(data: any = null) {
        if (!data) {
            return;
        }
        
        if (data.equipeProdutiva) {
            this.setEquipeProdutiva(data.equipeProdutiva);
        }
        
        if (data.realIpc) {
            this.setRealIpc(data.realIpc);
        }
        
        if (data.targetIpc) {
            this.setTargetIpc(data.targetIpc);
        }
        
        if (data.realIpi) {
            this.setRealIpi(data.realIpi);
        }
        
        if (data.targetIpi) {
            this.setTargetIpi(data.targetIpi);
        }
        
        if (data.turnover) {
            this.setTurnover(data.turnover);
        }
    }

    public setEquipeProdutiva(equipeProdutiva: number&Object) {
        this.equipeProdutiva = equipeProdutiva;
    }

    public setRealIpc(realIpc: number) {
        this.realIpc = realIpc;
    }

    public setTargetIpc(targetIpc: number) {
        this.targetIpc = targetIpc;
    }

    public setRealIpi(realIpi: number) {
        this.realIpi = realIpi;
    }

    public setTargetIpi(targetIpi: number) {
        this.targetIpi = targetIpi;
    }

    public setTurnover(turnover: number) {
        this.turnover = turnover;
    }

    public get simpleEquipeProdutiva() {
        return typeof this.equipeProdutiva == 'number';
    }
}