import {
    Directive,
    ElementRef,
    HostListener,
    OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appPercentMask]',
})
export class PercentMaskDirective implements OnInit {
    constructor(private el: ElementRef, private control: NgControl) {}

    ngOnInit() {
        setTimeout(() => {
            if (this.control.value) {
                this.control.valueAccessor.writeValue(
                    this.formatPercent(this.control.value)
                );
            }
        }, 100);
    }

    @HostListener('input', ['$event'])
    onInputChange(event: Event) {
        const input = event.target as HTMLInputElement;
        let value = input.value.replace(/\D/g, '');

        value = (parseFloat(value) / 100).toFixed(2);
        const formattedValue = this.formatPercent(value);
        input.value = formattedValue;

        // Atualiza o valor no controle
        this.control.viewToModelUpdate(value);
    }

    private formatPercent(value: string): string {
        value = value.toString();
        if (isNaN(parseFloat(value))) {
            value = '0.00';
        }

        return `%${value}`;
    }
}
