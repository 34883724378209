import { AssetSubcategory } from "pages/finances/asset-management/asset-subcategories/shared/asset-subcategory.model";
import { DateTime } from 'luxon';
import { File } from "pages/misc/files/shared/file.model";
import { Asset } from "pages/finances/asset-management/assets/shared/asset.model";
import { Validator } from "core/shared/validator";

export class FixedAsset extends Asset {

    public siengeMeasurementNumber: number = null;
    public assetSubcategory: AssetSubcategory = null;
    public privateArea: number = null;
    public commonArea: number = null;
    public address: string = null;
    public purchaseDate: string = null;
    public registrationNumber: string = null;
    public townRegistration: string = null;
    public image:  File = null;
    public residencyProof: File = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
        this.setGlpiTicket();
    }

    public get relationships() {
        let relationships = {
            assetSubcategory:  AssetSubcategory,
            image:             File,
            residencyProof:    File,
        };

        return Object.assign(relationships, super.relationships);
    }

    public get http_data() {
        let data = super.http_data;

        data.siengeMeasurementNumber = this.siengeMeasurementNumber;
        data.assetSubcategoryId      = this.assetSubcategory?.id;
        data.privateArea             = this.privateArea;
        data.commonArea              = this.commonArea;
        data.address                 = this.address;
        data.purchaseDate            = this.formattedPurchaseDate;
        data.registrationNumber      = this.registrationNumber;
        data.townRegistration        = this.townRegistration;

        if (this.residencyProof?.file) {
            data.residencyProof   = this.residencyProof;
        }
  
        return data;
    }

    public get isFixed(): boolean {
        return true;
    }

    public get assetCategoryType(): string {
        return 'fixed';
    }

    public get isRented(): boolean {
        return this.assetSubcategory?.name === 'Bem Locado';
    }

    public get fullDescription(): string {
        return this.description + ' ' + this.address;
    }

    public get siengeInfo(): string {
        if (this.isAdministrative) {
            return 'Medição ' + this.siengeMeasurementNumber
        }

        return 'Título ' + this.siengeBillId;
    }

    public get formattedPurchaseDate() {
        return this.purchaseDate ? 
            DateTime.fromISO(this.purchaseDate).toFormat('yyyy-MM-dd') : 
            null;
    }

    public validate() {
        let validator = new Validator;
        
        validator.validateRequired(this.costCenter, 'Centro de Custo / Obra');

        if (this.isAdministrative) {
            validator.validateRequired(this.siengeMeasurementNumber, 'Número da Medição Sienge');
        } else {
            validator.validateRequired(this.siengeBillId, 'Número do Título Sienge');
            validator.validateNumeric(this.siengeBillId, 'Número do Título Sienge');
        }
        
        validator.validateRequired(this.value, 'Valor da Medição');
        validator.validateNumeric(this.value, 'Valor da Medição');
        validator.validateRequired(this.assetSubcategory, 'Subcategoria do Bem Imóvel');
        validator.validateRequired(this.privateArea, 'Área Privada');
        validator.validateNumeric(this.privateArea, 'Área Privada');
        validator.validateRequired(this.commonArea, 'Área Comum');
        validator.validateNumeric(this.commonArea, 'Área Comum');
        validator.validateRequired(this.description, 'Descritivo do Bem');
        validator.validateRequired(this.address, 'Endereço');
        validator.validateStringMaxSize(this.address, 200, 'Endereço');
        validator.validateRequired(this.purchaseDate, 'Data de Compra / Término da Obra');
        
        if (!this.isRented) {
            validator.validateRequired(this.registrationNumber, 'Matrícula do Imóvel / Terreno / Alvará');
            validator.validateStringMaxSize(this.registrationNumber, 50, 'Matrícula do Imóvel / Terreno');
            validator.validateRequired(this.townRegistration, 'Inscrição Municipal');
            validator.validateStringMaxSize(this.townRegistration, 50, 'Inscrição Municipal');
        }

        validator.validateRequired(this.residencyProof, 'Comprovante de Residência');
    }
}
