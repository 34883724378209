import { Serializable } from "core/shared/serializable.model";
import { ActionChecklistItemCategory } from "pages/pv/action-checklist-item-categories/shared/action-checklist-item-category.model";
import { CorrectiveAction } from "pages/pv/corrective-actions/shared/corrective-action.model";

export class ActionChecklistItem extends Serializable {

    public id: string = null;
    public action_taken: string = null;
    public completed_at: string = null;
    public completion_date: string = null;
    public scheduled_at: string = null;
    public schedule_date: string = null;
    public corrective_action: CorrectiveAction = null;
    public action_checklist_item_category: ActionChecklistItemCategory = null
    public gap: string = null

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    get relationships() {
        return {
            corrective_action: CorrectiveAction,
            action_checklist_item_category: ActionChecklistItemCategory
        };
    }

    get status() {
        if (this.completed_at) {
            return 'completed';
        }

        if (this.scheduled_at) {
            return 'scheduled';
        }

        return 'pending';
    }

    get status_name() {
        switch (this.status) {
            case 'completed':
                return 'Realizado';
            case 'scheduled':
                return 'Planejado';
            case 'pending':
            default:
                return 'Pendente';
        };
    }

    get status_color() {
        switch (this.status) {
            case 'completed':
                return 'success';
            case 'scheduled':
                return 'primary';
            case 'pending':
            default:
                return 'medium';
        };
    }

    get http_data(): Object {
        return {
            action_taken: this.action_taken,
            schedule_date: this.schedule_date,
            completion_date: this.completion_date,
            action_checklist_item_category: this.action_checklist_item_category,
            gap: this.gap
        };
    }
}
