import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { ToastService } from 'core/shared/toast.service';
import { File } from 'pages/misc/files/shared/file.model';

@Component({
    selector: 'multiple-droppable-file-input',
    templateUrl: './multiple-droppable-file-input.component.html',
    styleUrls: ['./multiple-droppable-file-input.component.scss'],
})
export class MultipleDroppableFileInput {
    @Input('styled') styled: "compact"|"default" = 'default';
    @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
    @Output() filesEmitter = new EventEmitter();
    @Output() fileDeleteEmitter = new EventEmitter();
    files: Array<any> = [];
    private acceptedTypes: Array<string> = ['png', 'jpg', 'jpeg', 'pdf'];

    constructor(public toast: ToastService) {}

    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    deleteFile(index: number) {
        this.files.splice(index, 1);
        this.fileDeleteEmitter.emit(this.files);
    }

    deleteAllFiles() {
        this.files = [];
    }

    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            const fileType = item.name.split('.');

            if (!this.acceptedTypes.includes(fileType[fileType.length - 1])) {
                this.toast.warning(
                    `O tipo ${
                        fileType[fileType.length - 1]
                    } não é permitido. Os tipos permitidos são: ${this.acceptedTypes.join(
                        ', '
                    )}`
                );
                return 0;
            }

            if (item.size > 10485760) {
                this.toast.warning('Só são permitidos arquivos com até 10MB.');
                return 0;
            }
            const file = new File({
                name: item.name,
                size: item.size,
                file: item,
                mimeType: item.type,
            });
            this.files.push(file);
        }
        this.filesEmitter.emit(this.files);
        this.fileDropEl.nativeElement.value = '';
    }

    formatFilename(filename: string): string {
        if (!filename) {
          return '';
        }

        const limit = 30;

        const extension = filename.split('.').pop();

        const truncatedFilename = filename.substring(0, limit);

        return `${truncatedFilename}... .${extension}`;
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    }
}
