import { Serializable } from "core/shared/serializable.model";
import { User } from "pages/account/users/shared/user.model";
import { ConversationReply } from "pages/misc/conversations/shared/conversation-reply.model";

export class Conversation extends Serializable {

    public id: string = null;
    public userOne: User = null;
    public userTwo: User = null;
    public subjectableType: string = null;
    public subjectableId: string = null;

    public conversationReplies: ConversationReply[] = [];

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return {
            userOne: User,
            userTwo: User,
            conversationReplies: ConversationReply
        };
    }

    public setSubject(type: string, id: string) {
        this.subjectableId = id;
        this.subjectableType = type;
    }

    public get http_data() {
        return {
            subjectableType:    this.subjectableType,
            subjectableId:      this.subjectableId
        };
    }

    public canAnswer(userId: string) {
        if (this.userOne === null ||
            this.userTwo === null) {
            return true;
        }

        return (this.userOne?.id === userId
            || this.userTwo?.id === userId);
    }
}
