import { Serializable } from "core/shared/serializable.model";

export class AssetSubcategory extends Serializable {
    
    public name: string = null;
    public description: string = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

}