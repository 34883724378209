import { DashCardEntity } from 'pages/pv/dashboards/components/dash-card/dash-card-entity.interface';
import { PerformanceInfo } from 'pages/pv/dashboards/components/dash-card/performance-info.model';
import { TrainingInfo } from 'pages/pv/dashboards/components/dash-card/training-info.model';
import { CheckupInfo } from 'pages/pv/dashboards/components/dash-card/checkup-info.model';
import { SalesInfo } from 'pages/pv/dashboards/components/dash-card/sales-info.model';
import { IPersonAvatar } from 'pages/inputs/people/components/person-avatar/person.avatar.interface';
import { Person } from 'pages/inputs/people/shared/person.model';
import { SalesTeam } from 'pages/pv/sales-teams/shared/sales-team.model';
import { ThermometerInfo } from 'pages/pv/dashboards/components/dash-card/thermometer-info.model';
import { Summarizable } from 'pages/pv/summaries-screen/shared/summarizable.interface';

export class Manager extends Person implements IPersonAvatar, DashCardEntity, Summarizable {
    public managed_sales_teams: SalesTeam[] = [];
    public comment: string = null;

    public salesInfo: SalesInfo = new SalesInfo();
    public checkupInfo: CheckupInfo = new CheckupInfo();
    public trainingInfo: TrainingInfo = new TrainingInfo();
    public performanceInfo: PerformanceInfo = new PerformanceInfo();
    thermometerInfo: ThermometerInfo;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return Object.assign(super.relationships, {
            managed_sales_teams: SalesTeam,
        });
    }

    public reorderSalesTeams(orderBy: string) {
        this.managed_sales_teams.sort((a, b) => (a.name > b.name ? 1 : -1));

        switch (orderBy) {
            case 'goal':
                this.managed_sales_teams.sort(
                    (a, b) =>
                        b.salesInfo.contractsGoal - a.salesInfo.contractsGoal
                );
                break;
            case 'contracts':
                this.managed_sales_teams.sort(
                    (a, b) =>
                        b.salesInfo.grossContracts - a.salesInfo.grossContracts
                );
                break;
        }
    }

    public get entityId() {
        return this.id;
    }

    public get title() {
        return this.name;
    }

    public get subtitle() {
        return this.comment;
    }

    public get singular() {
        return 'manager';
    }

    public get plural() {
        return 'managers';
    }
}
