import { Serializable } from "core/shared/serializable.model";
import { CorrectiveActionService } from "pages/pv/corrective-actions/shared/corrective-action.service";

export class CorrectiveAction extends Serializable {

    public id: string = null;
    public name: string = null;
    public description: string = null;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }


    get http_data() {
        return {
            id: this.id,
            name: this.name,
            description: this.description
        };
    }

    getCorrectiveAction(id: string) {
        var service: CorrectiveActionService;
        service.getById(id).then(
            response => {
                this.id = response.id;
                this.name = response.name;
                this.description = response.description
            }
        );

    }

    edit(id: string) {
        var data = this.getCorrectiveAction(id);
    }
}
