import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
    @Input() label: string;
    @Input() minDate: string;
    @Input() maxDate: string;
	@Input() ngIf?: boolean = true;
    @Input() placeholder: string = "Selecione a data...";
	@Input() model?: any = null;
    @Output() onChange = new EventEmitter<any>();
	@Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('d') datepicker: NgbInputDatepicker;

    public change(event) {
		this.modelChange.emit(event);
        this.onChange.emit(event);
    }

    public ngbDate(stringDate: string): NgbDateStruct | null {
        if (!stringDate) {
            return null;
        }
        const date = new Date(stringDate);
        return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }

    public clear() {
        this.model = null;
        this.datepicker.close();
    }
}
