import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { AuthService } from "pages/account/auth/shared/auth.service";

@Directive({
    selector: '[userCannot]'
})
export class UserCannotDirective implements OnInit {

    @Input('userCannot') permission: Array<string>;

    constructor(
        private auth: AuthService,
        private elementRef: ElementRef,
    ) { }

    ngOnInit() {
        if (this.auth.userCan(this.permission)) {
            this.elementRef.nativeElement.style.display = "none";
        }
    }
}
