import { IPersonAvatar } from 'pages/inputs/people/components/person-avatar/person.avatar.interface';
import { Person } from 'pages/inputs/people/shared/person.model';
import { SalesTeam } from 'pages/pv/sales-teams/shared/sales-team.model';
import { DashCardEntity } from 'pages/pv/dashboards/components/dash-card/dash-card-entity.interface';
import { PerformanceInfo } from 'pages/pv/dashboards/components/dash-card/performance-info.model';
import { TrainingInfo } from 'pages/pv/dashboards/components/dash-card/training-info.model';
import { CheckupInfo } from 'pages/pv/dashboards/components/dash-card/checkup-info.model';
import { SalesInfo } from 'pages/pv/dashboards/components/dash-card/sales-info.model';
import { ThermometerInfo } from 'pages/pv/dashboards/components/dash-card/thermometer-info.model';

export class RegionalManager extends Person implements DashCardEntity, IPersonAvatar {
    public regional_managed_sales_teams: Array<SalesTeam> = [];

    public salesInfo: SalesInfo = new SalesInfo();
    public checkupInfo: CheckupInfo = new CheckupInfo();
    public trainingInfo: TrainingInfo = new TrainingInfo();
    public performanceInfo: PerformanceInfo = new PerformanceInfo();
    thermometerInfo: ThermometerInfo;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return Object.assign(super.relationships, {
            regional_managed_sales_teams: SalesTeam
        });
    }

    public get subtitle() {
        return this.comment;
    }

    public get entityId() {
        return this.id;
    }

    public get title() {
        return this.name;
    }

    public get singular() {
        return 'regionalManager';
    }

    public get plural() {
        return 'regionalManagers';
    }
}
