import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { environment } from './../../../../../../environments/environment';
import { AuthService } from 'pages/account/auth/shared/auth.service';
import { Person } from 'pages/inputs/people/shared/person.model';
import { Realtor } from 'pages/inputs/people/shared/realtor.model';
import { Manager } from 'pages/inputs/people/shared/manager.model';
import { RegionalManager } from 'pages/inputs/people/shared/regional-manager.model';
import { PersonService } from 'pages/inputs/people/shared/person.service';
import { ToastService } from 'core/shared/toast.service';

@Component({
    selector: 'person-profile',
    templateUrl: './person-profile.html',
    styleUrls: ['./person-profile.scss'],
    providers: [NavParams]
})
export class PersonProfile implements OnInit {
    @Input() public id: any = null;

    public person: Person | Realtor | Manager | RegionalManager = null;

    public loading: boolean = true;

    constructor(
        public auth: AuthService,
        private navParams: NavParams,
        private service: PersonService,
        private modalController: ModalController,
        public toast: ToastService
    ) {
        this.id = this.navParams.get('id');
    }
    public get imageUrl() {
        if (this.person && this.person.image) {
        return `${environment.url}/images/images/${this.person.image.path}`;
        } else {
            return this.defaultImageUrl;
        }
    }

    public get defaultImageUrl() {
        return '../../../../../../assets/avatar.svg';
    }

    public onImgError(event) {
        event.target.src = this.defaultImageUrl;
    }

    ngOnInit() {
        this.loading = true;
        this.service.getCard(this.id).then(
            (response) => {
                if (response.person.regional_managed_sales_teams) {
                    this.person = new RegionalManager(response.person);
                } else if (response.person.managed_sales_teams) {
                    this.person = new Manager(response.person);
                } else if (response.person.sales_teams) {
                    this.person = new Realtor(response.person);
                } else if (response.person) {
                    this.person = new Person(response.person);
                }
            }
        )
        .catch((err) => {
            this.toast.present(err.error.message, 'danger');
            this.dismiss();
            return;
        })
        .finally(() => this.loading = false);

    }

    public async dismiss(value?: any) {
        await this.modalController.dismiss(value);
    }
}
