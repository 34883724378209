import { IPersonAvatar } from './person.avatar.interface';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { environment } from '../../../../../../environments/environment';
import { PersonProfile } from 'pages/inputs/people/components/person-profile/person-profile';

@Component({
    selector: 'person-avatar',
    templateUrl: './person-avatar.html',
    styleUrls: ['./person-avatar.scss'],
    providers: [NavParams]
})
export class PersonAvatar implements OnInit {
    @Input() entity?: IPersonAvatar;
    @Input() size: string = 'ion-avatar-lg';
    @Input() align?: string;
    @Input() loading?: boolean = false;
    // @Input('person') avatarName: string = null;
    @Input() isFlow?: boolean = false;
    @Input() className?: string;
    @Input() canShowProfile?: boolean = true;

    constructor(
        private modalController: ModalController
    ) {

    }

    ngOnInit() {
        //
    }

    public get imageUrl() {
        if (this.entity && this.entity.image && this.entity.image.path) {
            return `https://api.prestes.com/images/images/${this.entity.image.path}`;
        } else {
            return this.defaultImageUrl;
        }
    }

    public get defaultImageUrl() {
        return '../../../../../../assets/avatar.svg';
    }

    public onImgError(event) {
        event.target.src = this.defaultImageUrl;
    }

	public async showProfile(event) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.entity || !this.canShowProfile) {
            return;
        }
        const modal = await this.modalController.create({
            component: PersonProfile,
            cssClass: 'person-profile-modal',
            componentProps: {
                id: this.entity.id,
            }
        });
        await modal.present();
    }
}
