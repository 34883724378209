import { CorrectiveAction } from 'pages/pv/corrective-actions/shared/corrective-action.model';
import { SurveyQuestion } from 'pages/pv/surveys/shared/survey-question.model';
import { Serializable } from 'core/shared/serializable.model';


export class SurveySection extends Serializable {
    public id: string = null;

    public name: string = null;

    public order: number = null;

    public survey_questions: Array<SurveyQuestion> = [];

    public corrective_actions: Array<CorrectiveAction> = [];

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public addQuestion() {
        this.survey_questions.push(
            new SurveyQuestion(this.survey_questions)
        );
    }
    public get relationships() {
        return {
            survey_questions: SurveyQuestion,
            corrective_actions: CorrectiveAction
        };
    }

    public removeQuestion(index) {
        this.survey_questions.splice(index, 1);
    }

    public syncCorrectiveAction(selectedActions: Array<CorrectiveAction>) {
        this.corrective_actions = selectedActions;
    }

    public hasCorrectiveAction(selectedAction: CorrectiveAction) {
        return this.corrective_actions.find (
            (correctiveAction) => correctiveAction.id === selectedAction.id
        );
    }

    public get http_data() {
        return {
            id: this.id,
            name: this.name,
            order: this.order,
            survey_questions: this.survey_questions.map(surveyQUestion => surveyQUestion.http_data),
            corrective_actions: this.corrective_actions.map(correctiveAction => correctiveAction.id)
        };
    }
}
