import { DateTime } from 'luxon';
import { File } from "pages/misc/files/shared/file.model";
import { Asset } from "pages/finances/asset-management/assets/shared/asset.model";
import { PreservationState } from "pages/finances/asset-management/preservation-states/shared/preservation-state.model";
import { Validator } from 'core/shared/validator';

export class GradualAsset extends Asset {

    public finishDate: string = null;
    public address: string = null;
    public area: number = null;

    public preservationState: PreservationState = null;
    public report: File = null;
    public image: File = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
        this.setGlpiTicket();
    }

    public get relationships() {
        let relationships = {
            preservationState:  PreservationState,
            image:             File,
            report:            File,
        };

        return Object.assign(relationships, super.relationships);
    }

    public get http_data() {
        return Object.assign({
            preservationStateId: this.preservationState?.id,
            area:                this.area,
            address:             this.address,
            finishDate:          this.formattedFinishDate,
            image:               this.image,
            report:              this.report
        }, super.http_data);
    }

    public get isGradual(): boolean {
        return true;
    }

    public get assetCategoryType(): string {
        return 'gradual';
    }

    public get formattedFinishDate() {
        return this.finishDate ? 
            DateTime.fromISO(this.finishDate).toFormat('yyyy-MM-dd') : 
            null;
    }

    public validate() {
        let validator = new Validator;

        validator.validateRequired(this.value, 'Valor Total Gasto');
        validator.validateNumeric(this.value, 'Valor Total Gasto');
        validator.validateRequired(this.costCenter, 'Centro de Custo / Obra');
        validator.validateRequired(this.preservationState, 'Estado de Conservação');
        validator.validateRequired(this.description, 'Descrição');
        validator.validateRequired(this.area, 'Área');
        validator.validateNumeric(this.area, 'Área');
        validator.validateRequired(this.address, 'Endereço');
        validator.validateStringMaxSize(this.address, 200, 'Endereço');
        validator.validateRequired(this.finishDate, 'Data de Término');
        validator.validateRequired(this.image, 'Foto');
        validator.validateRequired(this.report, 'Anexo: RG-CON-027 - Planilha para Compra Gradual e Capex');
    }
}
