import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from 'pages/account/auth/shared/auth.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router,
    ) { }

    canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
        if (!this.auth.userCan(next.data.permissions)) {
            return this.router.parseUrl('/403');
        }
        
        return true;
    }
}