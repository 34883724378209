import { BaseService } from 'core/shared/base.service';
import { Injector, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseResponse } from 'core/shared/base-response.interface';

@Injectable({
    providedIn: 'root'
})
export class ReportService extends BaseService {

    protected url: string = 'api/mvp/reports';


    public async getCheckupReport(month: string = null, salesTeamId = null): Promise<any> {
        if (month !== null && salesTeamId !== null) {
            var url = `/checkups/${month}/${salesTeamId}`;
        } else {
            var url = `/checkups/${month}`;
        }

        return super.handleBaseResponse(
            this.http.get(this.buildUrl(url)).toPromise()
        ).then(
            (response: BaseResponse) => response.data.report
        );
    }

    public getTrainingReport(month: string = null, salesTeamId: string = null): Promise<any> {
        if (month !== null && salesTeamId !== null) {
            var url = `/trainings/${month}/${salesTeamId}`;
        } else {
            var url = `/trainings/${month}`;
        }

        return super.handleBaseResponse(
            this.http.get(this.buildUrl(url)).toPromise()
        ).then(
            (response: BaseResponse) => response.data.report
        );
    }

    public getTrainingDoneReport(month: string = null, salesTeamId: string = null) {
        if (month !== null && salesTeamId !== null) {
            var url = `/trainings-done/${month}/${salesTeamId}`;
        } else {
            var url = `/trainings-done/${month}`;
        }

        return super.handleBaseResponse(
            this.http.get(this.buildUrl(url)).toPromise()
        ).then(
            (response: BaseResponse) => response.data.report
        );
    }

    public getLeadsReport(
        month: string = null,
        salesTeamId: string = null,
        realtorId: string = null
    ): Promise<any> {
        if (month !== null && salesTeamId !== null && realtorId !== null) {
            var url = `/leads/${month}/${salesTeamId}/${realtorId}`;
        } else if (month !== null && salesTeamId !== null) {
            var url = `/leads/${month}/${salesTeamId}`;
        }

        return super.handleBaseResponse(
            this.http.get(this.buildUrl(url), {responseType:'blob'}).toPromise()
        ).then(
            (response: BaseResponse) => response
        );
    }

    private convertToCSV(objArray, headerList) {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = ',';

        for (let index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = (i + 1) + '';
            for (let index in headerList) {
                let head = headerList[index];
                if (!array[i][head]) {
                    array[i][head] = 0
                }
                line += ',' + array[i][head];
            }
            str += line + '\r\n';
        }
        return str;
    }

    public downloadFile(data, filename = 'data', tab) {
        let headers = [
            'Ano',
            'Mês',
            'Regional',
            'Gerente de Vendas',
            'Corretor',
            'Abordar',
            'Pesquisar',
            'Apresentar',
            'Identificar e neutralizar Objeções',
            'Xeque-Mate',
            'Ampliar o relacionamento',
            'Ofertar todos os dias',
            'Otimismo',
            'Objetivo',
            'Foco',
            'Conhecimento',
            'Persistência',
            'Entusiasmo',
            'Humildade'
        ];

        if (tab === 'trainingsDone') {
            headers = [ 'Ano', 'Mês', 'Regional', 'Gerente de Vendas', 'Corretor', 'Categoria'];
        }

        const csvData = this.convertToCSV(data, headers);
        const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', filename + '.csv');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
}
