export class Validator {
    private messages: any = {
        required:      'O campo "{{fieldName}}" é obrigatório',
        stringMaxSize: 'O campo "{{fieldName}}" não precisa ter mais de {{maxStringSize}} caracteres.',
        stringSize:    'O campo "{{fieldName}}" precisa ter {{stringSize}} caracteres.',
        numeric:       'O campo "{{fieldName}}" deve ser numérico.'
    };

    constructor() {}

    private throwValidationMessage(validationType: string, fieldName: string, arg?: any) {
        let message = this.messages[validationType]
            .replace('{{fieldName}}', fieldName);

        if (validationType == 'stringSize') {
            message = message.replace('{{stringSize}}', arg);
        }

        if (validationType == 'stringMaxSize') {
            message = message.replace('{{stringMaxSize}}', arg);
        }

        throw message;
    }

    public validateNumeric(attribute: any, fieldName: string) {
        if (!attribute) {
            return true;
        }

        if (isNaN(attribute) && isNaN(parseFloat(attribute))) {
            this.throwValidationMessage('numeric', fieldName);
        }
    }

    public validateRequired(attribute: any, fieldName: string)  {
        if (!attribute || attribute.length === 0) {
            this.throwValidationMessage('required', fieldName);
        }
    }

    public validateStringMaxSize(attribute: any, maxLength: number, fieldName: string)  {
        if (!attribute) {
            return true;
        }

        if (attribute.toString().length > maxLength) {
            this.throwValidationMessage('stringMaxSize', fieldName, maxLength);
        }
    }

    public validateStringSize(attribute: any, length: number, fieldName: string)  {
        if (!attribute) {
            this.throwValidationMessage('stringSize', fieldName, length);
        }

        if (attribute.toString().length !== length) {
            this.throwValidationMessage('stringSize', fieldName, length);
        }
    }
}
