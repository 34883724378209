import { Serializable } from "core/shared/serializable.model";
import { Permission } from "pages/account/permissions/shared/permission.model";

export class PermissionCategory extends Serializable {
    
    public id:   string = null;
    public name: string = null;
    public type: string = null;
    public multiple: boolean = false;
    public permissions: Array<Permission> = [];

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    get relationships() {
        return {
            permissions: Permission
        };
    }
}   