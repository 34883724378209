import { RealDataTypes } from "pages/pv/dashboards/shared/real-data-types.type";

export class SalesInfo {

    public grossContracts: number = 0;
    public netContracts: number = 0;
    public leads: number = 0;
    public grossSalesValue: number = 0;
    public netSalesValue: number = 0;
    public contractsGoal: number = 0;

    constructor(data: any = null) {
        if (!data) {
            return;
        }

        this.leads           = data.leads           ? data.leads : 0;
        this.grossContracts  = data.grossContracts  ? data.grossContracts : 0;
        this.netContracts    = data.netContracts    ? data.netContracts : 0;
        this.netSalesValue   = data.netSalesValue   ? data.netSalesValue : 0;
        this.grossSalesValue = data.grossSalesValue ? data.grossSalesValue : 0;
        this.contractsGoal   = data.contractsGoal   ? data.contractsGoal : 0;
    }

    getContractsMade(contractType: RealDataTypes) {
        return this[contractType + 'Contracts'];
    }

    getSalesValue(contractType: RealDataTypes) {
        return this[contractType + 'SalesValue'];
    }

    getContractsColor(contractType: RealDataTypes) {
        return this[contractType + 'ContractsColor'];
    }

    get grossContractsColor() {
        return this.grossContracts >= this.contractsGoal ? 'success' : 'warning';
    }

    get netContractsColor() {
        return this.netContracts >= this.contractsGoal ? 'success' : 'warning';
    }

    getMediumTicket(contractType: RealDataTypes) {
        return this[contractType + 'MediumTicket'];
    }

    get grossMediumTicket() {
        if (this.grossContracts == 0) {
            return 0;
        }

        const mediumTicket = this.grossSalesValue / this.grossContracts;

        return Math.round((mediumTicket * 100) / 100).toFixed(2);
    }

    get netMediumTicket() {
        if (this.netContracts == 0) {
            return 0;
        }

        const mediumTicket = this.netSalesValue / this.netContracts;

        return Math.round((mediumTicket * 100) / 100).toFixed(2);
    }

    getConversion(contractType: RealDataTypes) {
        return this[contractType + 'Conversion'];
    }

    get grossConversion() {
        if (this.leads == 0) {
            return 0;
        }

        return Math.round((this.grossContracts / this.leads) * 100);
    }

    get netConversion() {
        if (this.leads == 0) {
            return 0;
        }

        return Math.round((this.netContracts / this.leads) * 100);
    }
}