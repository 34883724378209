import { Injectable } from '@angular/core';
import {
    NgbDateParserFormatter,
    NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class LocalDateParserFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const [day, month, year] = value.split(this.DELIMITER).map(Number);
            return { day, month, year };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date
            ? `${date.day.toString().padStart(2, '0')}/${date.month
                  .toString()
                  .padStart(2, '0')}/${date.year}`
            : '';
    }
}
