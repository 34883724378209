import { NgModule } from "@angular/core";
import { DocPipe } from "core/pipes/doc";

@NgModule({
	declarations: [
		DocPipe
	],
	exports: [
		DocPipe
	]
})
export class PipeModule { }