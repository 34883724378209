import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResponse } from 'core/shared/base-response.interface';
import { Serializable } from 'core/shared/serializable.model';
import { ToastService } from 'core/shared/toast.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BaseService {

    protected url: string;
    protected apiUrl: string = environment.url;
    protected http: HttpClient;
    protected toast: ToastService;

    constructor(
        protected injector: Injector
    ) {
        this.http  = injector.get(HttpClient);
        this.toast = injector.get(ToastService);
    }

    public get baseUrl() {
        return `${this.apiUrl}/${this.url}`;
    }

    protected buildUrl(path: any = '') {
        return `${this.baseUrl}${path}`;
    }

    protected handleGet(queryParams: string = ''): Promise<BaseResponse> {
        return this.handleBaseResponse(
            this.http.get(
                this.buildUrl('') + encodeURI(queryParams)
            ).toPromise()
        );
    }

    protected handleGetById(id: string): Promise<BaseResponse> {
        return this.handleBaseResponse(
            this.http.get(this.buildUrl('/' + id)).toPromise()
        );
    }

    protected handleStore(model: Serializable): Promise<BaseResponse> {
        return this.handleBaseResponse(
            this.http.post(this.buildUrl(), model.http_data).toPromise()
        );
    }

    protected handleUpdate(model: Serializable): Promise<BaseResponse> {
        return this.handleBaseResponse(
            this.http.put(this.buildUrl('/' + model.id), model.http_data).toPromise()
        );
    }

    protected handleDelete(model: Serializable): Promise<BaseResponse> {
        return this.handleBaseResponse(
            this.http.delete(this.buildUrl('/' + model.id)).toPromise()
        );
    }

    /**
     * Use esta função para lidar com as mensagens do back-end via toast
     */
    protected handleBaseResponse(promise: Promise<any>): Promise<BaseResponse> {
        return promise.then(
            (response: BaseResponse) => {
                this.toastSuccess(response);
                return response;
            },
            (errorResponse: HttpErrorResponse) => {
                this.toastError(errorResponse);
                return errorResponse;
            }
        );
    }

    protected toastSuccess(response: BaseResponse): boolean {
        if (response.message) {
            this.toast.present(response.message, 'success');
        }

        return true;
    }

    protected toastError(errorResponse: HttpErrorResponse): boolean {

        if (errorResponse.status !== 500) {
            if (errorResponse.error.message) {
                this.toast.present(
                    errorResponse.error.message,
                    'warning'
                );
            }
        } else {
            this.toast.present(
                'Ocorreu algum problema ao se comunicar com o servidor. Tente novamente mais tarde.',
                'danger'
            );
        }

        return false;
    }

    private buildFormData(formData, data, parentKey?) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
          Object.keys(data).forEach(key => {
            this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
          });
        } else {
          const value = data == null ? '' : data;

          formData.append(parentKey, value);
        }
      }

    public jsonToFormData(data) {
        const formData = new FormData();

        this.buildFormData(formData, data);

        return formData;
      }
}
