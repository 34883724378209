import { format, parseISO, isValid } from 'date-fns';
import { User } from 'pages/account/users/shared/user.model';
import { Position } from 'pages/inputs/positions/shared/position.model';
import { Profile } from 'pages/account/profiles/shared/profile.model';
import { SalesTeam } from 'pages/pv/sales-teams/shared/sales-team.model';
import { IPersonAvatar } from 'pages/inputs/people/components/person-avatar/person.avatar.interface';
import { Serializable } from 'core/shared/serializable.model';


export class Person extends Serializable implements IPersonAvatar {
    public id: string = null;
    public name: string = null;
    public document: string = null;
    public comment: string = null;
    public employment_date: any = null;
    public unemployment_date: any = null;
    public position: Position = null;
    public external_people: Array<any> = [];
    public maturity: string = null;
    public profile: Profile = null;
    public user: User = null;
    public email: string = null;
    public equipeProdutiva: number;
    public ipg: number;
    public turnover: number;
    public ipc: number;
    public maturities: any = null;
    public sales_teams: Array<SalesTeam>;
    public managed_sales_teams: Array<SalesTeam>;
    public regional_managed_sales_teams: Array<SalesTeam>;
    public selected: boolean = true;
    public active: boolean = true;
    public accomplishedIpg: number;
    public idealIpg: number;
    public accomplishedIpc: number;
    public idealIpc: number;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
        this.employment_date = this.formatDate(this.employment_date, 'yyyy-MM-dd');
        this.unemployment_date = this.formatDate(this.unemployment_date, 'yyyy-MM-dd');
    }

    /**
     * @inheritdoc
     */
    public get relationships() {
        return {
            position: Position,
            profile: Profile,
            user: User
        };
    }

    /**
     * @inheritdoc
     */
    public get http_data() {
        return {
            email: this.email,
            name: this.name,
            document: this.document ? this.document.replace(/[`~!@#$%^&*()_|+\-=?;:'',.<>\{\}\[\]\\\/]/gi, '') : null,
            employment_date: this.employment_date,
            unemployment_date: this.unemployment_date,
            position_id: this.position?.id
        };
    }

    public getEmploymentDate() {
        return this.formatDate(this.employment_date, 'dd/MM/yyyy');
    }

    public formatDate(date: string, formatDate: string) {
        const dateISO = parseISO(date);
        if (isValid(dateISO)) {
            return format(dateISO, formatDate);
        }
        return '';
    }

    public get maturityColor(): string {
        if (this.maturity === 'Iniciante') {
            return 'avatar-iniciante';
        } else if (this.maturity === 'Júnior') {
            return 'avatar-junior';
        } else if (this.maturity === 'Pleno') {
            return 'avatar-pleno';
        } else if (this.maturity === 'Sênior') {
            return 'avatar-senior';
        } else {
            return 'avatar-padrao';
        }
    }

    public get image() {
        return this.user?.image;
    }

    public get maturityClass() {
        switch (this.maturity) {
            case 'Iniciante':
                return 'light';
            case 'Júnior':
                return 'prestes-green';
            case 'Pleno':
                return 'prestes-blue';
            case 'Sênior':
                return 'prestes-yellow';
            default:
                return 'primary';
        }
    }

    /**
     * Se ao menos uma equipe gerenciada é interna
     * @return Boolean
     * */
    public get managesInternalSalesTeam() {

        const isInternalTeam = salesTeam => salesTeam.type === 'internal';
        if(this.managed_sales_teams) {
            return this.managed_sales_teams.some(isInternalTeam);
        }

        if(this.regional_managed_sales_teams) {
            return this.regional_managed_sales_teams.some(isInternalTeam);
        }

        return false;
    }

}
