import { Serializable } from 'core/shared/serializable.model';

export class FeedbackCategory extends Serializable {

    public id: string = null;
    public name: string = null;
    public module: string = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get http_data() {
        return {
            id: this.id,
            name: this.name,
            module: this.module
        };
    }

    public static fromArray(dataArray: any[]): FeedbackCategory[] {
        return dataArray.map(data => new FeedbackCategory(data));
    }
}
