import { User } from 'pages/account/users/shared/user.model';
import { FeedbackCategory } from 'pages/misc/feedback/shared/feedback-category.model';
import { Serializable } from 'core/shared/serializable.model';


export class Feedback extends Serializable {

	public id: string = null;
	public title: string = null;
	public description: string = null;
	public category: string = null;
	public module: string = null;
	public user: User = null;
	public glpiTicketUrl: string = null; 
	public feedbackCategory: FeedbackCategory = null;
	public feedbackCategoryId: string = null

	constructor(data: Object = {}) {
		super();
		this.serialize(data);
	}

	get relationships() {
		return {
			user: User,
			feedbackCategory: FeedbackCategory
		};
	}

	get http_data() {
		return {
			description: 		  this.description,
			feedback_category_id: this.feedbackCategory?.id,
			user: this.user?.id
		};
	}
}
