import { Serializable } from "core/shared/serializable.model";
import { User } from "pages/account/users/shared/user.model";
import { AssetCategory } from "pages/finances/asset-management/asset-categories/shared/asset-category.model";
import { Company } from "pages/inputs/companies/shared/company.model";
import { CostCenter } from "pages/inputs/cost-centers/shared/cost-center.model";
import { File } from "pages/misc/files/shared/file.model";
import { GlpiTicket } from "pages/external/glpi/shared/glpi-ticket.model";

export class Asset extends Serializable {

    public company: Company = null;
    public costCenter: CostCenter = null;
    public currentCostCenter: CostCenter = null;
    public assetCategory: AssetCategory = null;
    public comments: string = null;
    public status: string = null;
    public glpiTicketId: number = null;
    public siengeBillId: number = null;
    public assetNumber: string = null;
    public description: string = null;
    public glpiTicket: GlpiTicket = null;
    public value: number = null;
    public writtenOff: boolean = null;
    public writtenOffAt: string = null;
    public deniedAt: string = null;
    public deniedByUser: User = null;
    public denialJustification: string = null;
    public notAsset: boolean = false;
    public residualValue: number = null;
    public expanded: boolean = false;
    public activeForm: boolean = false;
    public editingAssetNumber: boolean = false;
    public oldAssetNumber: string = null;
    public editingSiengeBillId: boolean = false;
    public oldSiengeBillId: number = null;
    public assetIdentificationProof: File = null;
    public assetIdentificationProofToStore: File = null;
    
    constructor(data: any = {}) {
        super();
        this.serialize(data);
        this.setGlpiTicket();
    }

    public get relationships() {
        return {
            company:           Company,
            costCenter:        CostCenter,
            currentCostCenter: CostCenter,
            assetCategory:     AssetCategory,
            assetIdentificationProof: File,
            glpiTicket:        GlpiTicket,
            deniedByUser:      User,
        };
    }

    public get isMovable(): boolean {
        return false;
    }

    public get isFixed(): boolean {
        return false;
    }

    public get isGradual(): boolean {
        return false;
    }

    public get assetCategoryType(): string {
        return "";
    }

    public get isAdministrative() {
        return this.company?.administrative;
    }

    public get isConstruction() {
        return !this.company?.administrative;
    }

    public get isRented(): boolean {
        return false;
    }

    public wipeCostCenter() {
        this.costCenter = null;
    }

    public finishForm() {
        this.currentCostCenter = this.costCenter;
        this.currentCostCenter.company = this.company;
        this.activeForm = false;
    }

    public get fullDescription(): string {
        return this.description;
    }

    public get siengeInfo(): string {
        return '';
    }

    public setGlpiTicket() {
        if (!this.glpiTicketId) {
            return;
        }

        this.glpiTicket = new GlpiTicket({
            id: this.glpiTicketId
        });
    }

    public get http_data() {
        let data: any = {
            assetCategoryType: this.assetCategoryType,
            assetCategoryId:   this.assetCategory?.id,
            description:       this.description,
            value:             this.value,
            companyId:         this.company?.id,
            assetCompanyType:  this.company?.type,
            costCenterId:      this.costCenter?.id,
            comments:          this.comments,
            notAsset:          this.notAsset ? 1 : 0
        };

        if (this.isConstruction) {
            data.siengeBillId = this.siengeBillId;
        }

        return data;
    }

    public editAssetNumber() {
        this.oldAssetNumber = this.assetNumber;
        this.editingAssetNumber = true;
    }

    public updateAssetNumber() {
        this.editingAssetNumber = false;
    }

    public cancelAssetNumberEdit() {
        this.editingAssetNumber = false;
        this.assetNumber = this.oldAssetNumber;
    }

    public editSiengeBillId() {
        this.oldSiengeBillId = this.siengeBillId;
        this.editingSiengeBillId = true;
    }

    public updateSiengeBillId() {
        this.editingSiengeBillId = false;
    }

    public cancelSiengeBillIdEdit() {
        this.editingSiengeBillId = false;
        this.siengeBillId = this.oldSiengeBillId;
    }

    public get assetNumberIsEditable() {
        return this.siengeBillId || this.isGradual;
    }

    public get statusColor() {
        switch (this.status) {
            case 'Pendente Abertura de Chamado':
                return 'warning';
            case 'Pendente Lançamento Central de Faturas':
                return 'warning';
            case 'Pendente Cadastro do Título':
                return 'secondary';
            case 'Pendente Cadastro do Ativo':
                return 'primary';
            case 'Pendente Comprovante de Identificação do Patrimônio':
                return 'warning';
            case 'Rejeitado':
                return 'danger';
            case 'Concluído':
                return 'prestes-green';
            default:
                return 'medium';
        }
    }

    public validate() {
        //
    }
}
