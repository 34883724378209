import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { AuthService } from "pages/account/auth/shared/auth.service";

@Directive({
    selector: '[userCan]'
})
export class UserCanDirective implements OnInit {

    @Input('userCan') permission?: Array<string | Array<string | Array<string>>>;

    constructor(
        private auth: AuthService,
        private elementRef: ElementRef,
    ) { }

    ngOnInit() {
        if (!this.permission) {
            return;
        }
        
        if (!this.auth.userCan(this.permission)) {
            this.elementRef.nativeElement.style.display = "none";
        }
    }
}
