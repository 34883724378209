import { Serializable } from 'core/shared/serializable.model';
import { Product } from './product.model';

export class ProductTipology extends Serializable {

    public id: string = null;
    public name: string = null;
    public simulationEnabled: boolean = false;

    public averageTicket: number = 0;
    public averageEvaluation: number = 0;
    public totalSalesValue: number = 0;
    public totalUnits: number = 0;
    public availableUnits: number = 0;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get http_data() {
        return {
            id: this.id,
            simulationEnabled: this.simulationEnabled
        };
    }
}
