import { BarChartData } from 'core/components/charts/shared/bar-chart-data.model';
import { BarChartDatum } from 'core/components/charts/shared/bar-chart-datum.model';
import { ChartData } from 'core/components/charts/shared/chart-data.model';
import { Serializable } from 'core/shared/serializable.model';
import { DateTime } from 'luxon';
import { User } from 'pages/account/users/shared/user.model';
import { Manager } from 'pages/inputs/people/shared/manager.model';
import { Person } from 'pages/inputs/people/shared/person.model';
import { Realtor } from 'pages/inputs/people/shared/realtor.model';
import { Product } from 'pages/inputs/products/shared/product.model';
import { CheckupSection } from 'pages/pv/checkups/shared/checkup-section.model';
import { SurveyCategory } from 'pages/pv/surveys/shared/survey-category.model';
import { Survey } from 'pages/pv/surveys/shared/survey.model';

export class Checkup extends Serializable {

    public id: string                              = null;
    public survey: Survey                          = null;
    public manager: Person                         = null;
    public respondent: Person                      = null;
    public date: Date                              = null;
    public checkup_type: string                    = null;
    public sales_funnel_id: string                 = null;
    public sales_team_id: string                   = null;
    public lead_id: string                         = null;
    public checkup_sections: Array<CheckupSection> = [];
    public checkup_start: Date                     = null;
    public checkup_end: string                     = null;
    public category: SurveyCategory                = null;
    public product: Product                        = null;
    public evaluator: User                         = null;
    public score: number = null;
    public status: string = null;
    public evaluated_at: string = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    get relationships() {
        return {
            checkup_sections: CheckupSection,
            survey:           Survey,
            manager:          Manager,
            respondent:       Realtor,
            product:          Product,
            evaluator:        User
        };
    }

    get http_data() {
        return {
            id:               this.id,
            date:             this.date,
            checkup_type:     this.checkup_type,
            checkup_sections: this.checkup_sections.map(checkup_section => checkup_section.http_data),
            product_id:       this.product_id,
            survey_id:        this.survey.id,
            manager_id:       this.manager_id,
            respondent_id:    this.respondent.id,
            sales_funnel_id:  this.sales_funnel_id,
            sales_team_id:    this.sales_team_id,
            lead_id:          this.lead_id,
            checkup_start:    this.checkup_start,
            checkup_end:      this.checkup_end,
        };
    }

    get product_id() {
        return this.product ? this.product.id : null;
    }

    get month() {
        return this.date ?
            DateTime.fromFormat(this.date, 'yyyy-MM-dd').setLocale('pt').toFormat('MMMM/yy') :
            null;
    }

    /**
     * Média das notas das letras (sections) do checkup
     *
     * @return number
     */
    get total_score(): number {
        var scores = this.checkup_sections.map(
            section => section.total_score
        );

        if (!scores.length) {
            return 0;
        }

        var sum = scores.reduce((a, b) => (a + b), 0);

        return Math.ceil(sum / scores.length * 10) / 10;
    }

    get color() {
        if (this.total_score > 7) {
            return "success";
        } else if (this.total_score >= 6 && this.total_score <= 7) {
            return "warning";
        } else if (this.total_score < 6) {
            return "danger";
        }
    }

    get scoreColor() {
        if (this.score > 7) {
            return "success";
        } else if (this.score >= 6 && this.score <= 7) {
            return "warning";
        } else if (this.score < 6) {
            return "danger";
        }
    }

    get manager_id() {
        return this.manager ? this.manager.id : null;
    }

    get duration() {
        if (!this.checkup_start || !this.checkup_end) {
            return null;
        }

        var start = DateTime.fromFormat(this.checkup_start, 'yyyy-MM-dd HH:mm:ss');
        var end = DateTime.fromFormat(this.checkup_end, 'yyyy-MM-dd HH:mm:ss');

        return end.diff(start, ['minutes', 'seconds']).toHuman({ listStyle: "long" });
    }

    get checkup_start_datetime() {
        return this.checkup_start ?
            DateTime.fromFormat(this.checkup_start, 'yyyy-MM-dd HH:mm:ss').toFormat('H:mm') :
            null;
    }

    get formatted_date() {
        return this.date ?
            DateTime.fromFormat(this.checkup_start, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy') :
            null;
    }

    get checkup_end_datetime() {
        return this.checkup_end ?
            DateTime.fromFormat(this.checkup_end, 'yyyy-MM-dd HH:mm:ss').toFormat('H:mm') :
            null;
    }

    get time() {
        return this.checkup_start_datetime + ' - ' + this.checkup_end_datetime + ' - ' + this.duration + ' minuto (s)';
    }

    get chart_data() {
        return this.checkup_sections.map(
            section => [section.short_name, section.total_score]
        );
    }

    get statusDetails() {
        switch (this.status) {
            case 'pending_approval':
                return {
                    color: 'warning',
                    title: 'Pendente',
                    icon: 'hourglass'
                };
            case 'approved':
                return {
                    color: 'success',
                    title: 'Aprovada',
                    icon: 'checkmark'
                };
            case 'rejected':
                return {
                    color: 'danger',
                    title: 'Rejeitada',
                    icon: 'close'
                };
            default:
                return {
                    color: 'medium',
                    title: '',
                    icon: 'question-circle'
                };
        }
    }

    get sectionToChartData() {
        return new BarChartData('', this.checkup_sections.map (
            section => new BarChartDatum(
                section.name,
                section.score,
                [],
                section.scoreHexColor
            )
        ));
    }
}
