import { Serializable } from 'core/shared/serializable.model';

export class IptuPaymentInstallment extends Serializable {

    public id:  string = null;
    public dueDate: string = null;
    public paymentValue: number = null;
    public siengeBillInstallmentIndex: number = null;
    public siengeBillPaymentStatus: string = null;
    public glpiCancellationTicketId: number = null;
    public fileId: string = null;
    public fileName: string = null;
   
    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }
}
