import { Serializable } from "core/shared/serializable.model";
import { Permission } from "pages/account/permissions/shared/permission.model";

export class Profile extends Serializable {

    public id: string = null;
    public name: string = null;

    public permissions: Array<Permission> = [];

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    get relationships() {
        return {
            permissions: Permission
        };
    }

    can(type: string, category: string) {
         
        var permission = this.permissions.find(
            (permission) => permission.has(type, category)
        );
            
        if (permission) {           
            return permission.allow;
        }

        return false;
    }

    get access_type() {
        let permission = this.permissions.find(
            (permission) => permission.permission_category.type == 'access_type' && permission.allow
        );

        if (permission) {
            return permission.type;
        }

        return null;
    }

    get access_type_name() {
        let permission = this.permissions.find(
            (permission) => permission.permission_category.type == 'access_type' && permission.allow
        );

        if (permission) {
            return permission.name;
        }

        return null;
    }

    get http_data() {
        return {
            id: this.id,
            name: this.name,
            permission: this.permissions
        };
    }
}