import { Serializable } from "core/shared/serializable.model";

export class ActionChecklistItemCategory extends Serializable {
    public id: string = null;
    public name: string = null;
    public description: string = null

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    get http_data() {
        return {
            id: this.id,
            name: this.name,
            description: this.description
        };
    }
}
