import { Serializable } from "core/shared/serializable.model";
import { User } from "pages/account/users/shared/user.model";
import { Conversation } from "pages/misc/conversations/shared/conversation.model";

export class ConversationReply extends Serializable {

    public id: string = null;
    public user: User = null;
    public body: string = null;
    public status: string = null;
    public sentAt: string = null;
    public editedAt: string = null;
    public viewedAt: string = null;

    public conversation: Conversation = null;

    public editingBody: boolean = false;
    public oldBody: string = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return {
            user: User,
            conversation: Conversation
        };
    }

    public setBody(body: string) {
        this.body = body;
    }


    public editBody() {
        this.oldBody = this.body;
        this.editingBody = true;
    }

    public updateBody() {
        this.editingBody = false;
    }

    public cancelBodyEdit() {
        this.editingBody = false;
        this.body = this.oldBody;
    }


    public get http_data() {
        return {
            body:       this.body,
            status:     this.status
        };
    }
}
