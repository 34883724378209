import { Component, OnInit, OnChanges } from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { DateTime } from 'luxon';
import { SalesTeamService } from 'pages/pv/sales-teams/shared/sales-team.service';
import { ToastService } from 'core/shared/toast.service';
import { AuthService } from 'pages/account/auth/shared/auth.service';
import { LocaleService } from 'core/shared/locale.service';
import { ReportService } from 'pages/pv/reports/shared/report.service';
import { Realtor } from 'pages/inputs/people/shared/realtor.model';
import { SalesTeam } from 'pages/pv/sales-teams/shared/sales-team.model';


@Component({
    templateUrl: './create.page.html',
    styleUrls: ['./create.page.scss'],
})
export class ReportCreatePage implements OnInit, OnChanges {

    public salesTeams: Array<SalesTeam>;
    public salesTeam: SalesTeam = null;
    public realtors: Array<Realtor>;
    public realtor: Realtor = null;
    public month: string;
    public reportType: string = 'checkups';
    public loading: boolean = true;

    constructor(
        public locale: LocaleService,
        public service: ReportService,
        public auth: AuthService,
        public toast: ToastService,
        public salesTeamService: SalesTeamService
    ) { }

    ngOnInit() {
        this.getSalesTeams();
    }

    ngOnChanges() {
      this.getRealtors();
    }

    ionViewDidEnter() {
    }

    changeTab(event) {
        this.reportType = (event.detail.value);
    }

    save() {
        if (!this.month) {
            this.toast.present('O campo mês deve ser preenchido', 'danger');
            return 0;
        }

        const name = this.salesTeam ? this.salesTeam.name : 'Geral';

        const stringCheckups = 'Relatório de Avaliacões ';
        const stringTraining = 'Relatório de Treinamentos ';
        const stringTrainingDone = 'Relatório de Treinamentos Realizados ';
        const stringLeads = 'Relatório de Leads ';
        const year = DateTime.fromISO(this.month).setLocale('pt').toFormat('MMMM-yyyy');
        switch (this.reportType) {
            case 'checkups':
                if (this.salesTeam) {
                    this.service.getCheckupReport(this.month, this.salesTeam.id).then(
                        (report: any) => {
                            if (report === 0) {
                                this.toast.present('Não há dados para esse período', 'warning');
                            } else {
                                this.service.downloadFile(report, `${stringCheckups} ${name} ${year}`, this.reportType);
                                this.toast.present('Arquivo pronto para download', 'success');
                            }
                        }
                    );
                } else {
                    this.service.getCheckupReport(this.month).then(
                        (report: any) => {
                            if (report === 0) {
                                this.toast.present('Não há dados para esse período', 'warning');
                            } else {
                                this.service.downloadFile(report, `${stringCheckups} ${name} ${year}`, this.reportType);
                                this.toast.present('Arquivo pronto para download', 'success');
                            }
                        }
                    );
                }

                break;
            case 'trainings':
                if (this.salesTeam) {
                    this.service.getTrainingReport(this.month, this.salesTeam.id).then(
                        (report: any) => {
                            if (report === 0) {
                                this.toast.present('Não há dados para esse período', 'warning');
                            } else {
                                this.service.downloadFile(report, `${stringTraining} ${name} ${year}`, this.reportType);
                                this.toast.present('Arquivo pronto para download', 'success');
                            }
                        }
                    );
                } else {
                    this.service.getTrainingReport(this.month).then(
                        (report: any) => {
                            if (report === 0) {
                                this.toast.present('Não há dados para esse período', 'warning');
                            } else {
                                this.service.downloadFile(report, `${stringTraining} ${name} ${year}`, this.reportType);
                                this.toast.present('Arquivo pronto para download', 'success');
                            }
                        }
                    );
                }
                break;
            case 'trainingsDone':
                if (this.salesTeam) {
                    this.service.getTrainingDoneReport(this.month, this.salesTeam.id).then(
                        (report: any) => {
                            if (report === 0) {
                                this.toast.present('Não há dados para esse período', 'warning');
                            } else {
                                this.service.downloadFile(report, `${stringTrainingDone} ${name} ${year}`, this.reportType);
                                this.toast.present('Arquivo pronto para download', 'success');
                            }
                        }
                    );
                } else {
                    this.service.getTrainingDoneReport(this.month).then(
                        (report: any) => {
                            if (report === 0) {
                                this.toast.present('Não há dados para esse período', 'warning');
                            } else {
                                this.service.downloadFile(report, `${stringTrainingDone} ${name} ${year}`, this.reportType);
                                this.toast.present('Arquivo pronto para download', 'success');
                            }
                        }
                    );
                }
                break;
            case 'leads':
              if (this.salesTeam) {
                this.service.getLeadsReport(this.month, this.salesTeam.id)
                .then( (report: any) => {
                  const newBlob = new Blob([report], {type: 'text/csv'});

                  const data = window.URL.createObjectURL(newBlob);

                  const link = document.createElement('a');
                  link.href = data;
                  link.download = `${stringLeads} ${name} ${year}`;

                  link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

                  setTimeout(() => {
                      window.URL.revokeObjectURL(data);
                      link.remove();
                  }, 100);
                });
            } else if (this.salesTeam && this.realtor) {
              this.service.getLeadsReport(this.month, this.salesTeam.id, this.realtor.id)
              .then( (report: any) => {
                const newBlob = new Blob([report], {type: 'text/csv'});

                const data = window.URL.createObjectURL(newBlob);

                const link = document.createElement('a');
                link.href = data;
                link.download = `${stringLeads} ${name} ${year}`;

                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

                setTimeout(() => {
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
              });
          }
        }
    }

    getSalesTeams() {
        this.loading = true;
        this.salesTeamService.get('?exists[]=manager_id&exists[]=regional_manager_id')
            .then((salesTeams: SalesTeam[]) => {
                this.salesTeams = salesTeams;
                this.loading = false;
            });
    }

    get realtorInputStatus() {
      return this.reportType === 'leads' ? 'false' : 'true';
    }

    getRealtors() {
      this.loading = true;
      if (this.salesTeam != null) {
        this.realtors = this.salesTeam.realtors.map(realtor => new Realtor(realtor));
      }
      this.loading =false;
    }

    updateData() {
        this.month = DateTime.fromISO(this.month).toFormat('yyyy-MM-dd');
    }

    salesTeamChange(event: {
        component: IonicSelectableComponent;
        value: any;
    }) {
        this.salesTeam = event.value;
        this.getRealtors();
    }

    realtorChange(event: {
      component: IonicSelectableComponent;
      value: any;
  }) {
      this.realtor = event.value;
  }
}
