export class ChartData {
    public categoryName: string;

    public seriesName: string;

    public value: number|number[];

    public color?: string;

    public type: string = 'bar';

    public formatter: string = null;

    public additionalLabel: any = null;

    constructor(
        categoryName: string,
        value: number|number[],
        color?: string,
        seriesName?: string,
        type?: string,
        formatter?: string,
        additionalLabel?: any
    ) {
        this.categoryName = categoryName;
        this.value        = value;
        this.seriesName   = seriesName;
        this.additionalLabel = additionalLabel;

        if (color) {
            this.color = color;
        }

        if (type) {
            this.type = type;
        }

        if (formatter) {
            this.formatter = formatter;
        }
    }

    public get legend() {
        return this.categoryName;
    }
}
