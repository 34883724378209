import { BaseService } from 'core/shared/base.service';
import { Injectable } from '@angular/core';
import { SalesTeam } from 'pages/pv/sales-teams/shared/sales-team.model';
import { BaseResponse } from 'core/shared/base-response.interface';
import { Realtor } from 'pages/inputs/people/shared/realtor.model';

@Injectable({
    providedIn: 'root'
})
export class SalesTeamService extends BaseService {

    protected url: string = 'api/mvp/sales-teams';

    /**
     * `GET /api/mvp/sales-teams`
     *
     * Get SalesTeams
     */
    public async get(queryParams: string = ''): Promise<SalesTeam[]> {
        return super.handleGet(queryParams).then(
            (response: BaseResponse) => response.data.salesTeams.map(
                (salesTeam) => new SalesTeam(salesTeam)
            )
        );
    }

    /**
     * `GET /api/mvp/sales-teams/{salesTeamId}`
     *
     * Get SalesTeam By Id
     */
    public async getById(id: string): Promise<SalesTeam> {
        return super.handleGetById(id).then(
            (response: BaseResponse) => new SalesTeam(response.data.salesTeam)
        );
    }

    /**
     * `GET /api/mvp/sales-teams/realtors/{salesTeamId}/{month}`
     *
     * Get Realtors By Month
     */
    public async getRealtorsByMonth(salesTeamId: string, month: string): Promise<Realtor[]> {
        return super.handleBaseResponse(
            this.http.get(this.buildUrl(`/realtors/${salesTeamId}/${month}`)).toPromise()
        ).then(
            (response: BaseResponse) => response.data.realtors.map(
                (realtor) => new Realtor(realtor)
            )
        );
    }

    /**
     * `PUT /api/mvp/sales-teams/{salesTeamId}`
     *
     * Update SalesTeam
     */
    public async update(salesTeam: SalesTeam): Promise<SalesTeam> {
        return super.handleUpdate(salesTeam).then(
            (response: BaseResponse) => new SalesTeam(response.data.salesTeam)
        );
    }

    /**
     * `PUT /api/mvp/sales-teams/{salesTeamId}/add-realtor`
     *
     * Add Realtor
     */
    public async addRealtor(salesTeamId: string, realtorId: string, entryDate: string): Promise<Realtor> {
        return super.handleBaseResponse(
            this.http.put(this.buildUrl(`/${salesTeamId}/add-realtor`), {
                realtor_id: realtorId,
                entry_date: entryDate
            }).toPromise()
        ).then(
            (response: BaseResponse) => new Realtor(response.data.realtor)
        );
    }

    /**
     * `PUT /api/mvp/sales-teams/{salesTeamId}/remove-realtor`
     *
     * Remove Realtor
     */
    public async removeRealtor(salesTeamId: string, realtorId: string, removalDate: string): Promise<Realtor> {
        return super.handleBaseResponse(
            this.http.put(this.buildUrl(`/${salesTeamId}/remove-realtor`), {
                realtor_id: realtorId,
                removal_date: removalDate
            }).toPromise()
        ).then(
            (response: BaseResponse) => new Realtor(response.data.realtor)
        );
    }


    /**
     * `PUT /api/mvp/sales-teams/{salesTeamId}/transfer-realtor`
     *
     * Transfer Realtor
     */
    public async transferRealtor(
        salesTeamId: string,
        realtorId: string,
        transferDate: string,
        targetSalesTeamId: string
    ): Promise<Realtor> {
        return this.handleBaseResponse(
            this.http.put(this.buildUrl(`/${salesTeamId}/transfer-realtor`), {
                realtor_id: realtorId,
                target_sales_team_id: targetSalesTeamId,
                transfer_date: transferDate
            }).toPromise()
        ).then(
            (response: BaseResponse) => new Realtor(response.data.realtor)
        );
    }
}
