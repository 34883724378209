import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MenuPage } from 'core/components/side-menu/menu-page';
import { MenuPages } from 'core/components/side-menu/menu-pages';
import { AuthService } from 'pages/account/auth/shared/auth.service';

@Component({
    selector: 'side-menu',
    templateUrl: 'side-menu.component.html',
    styleUrls: ['side-menu.component.scss']
})
export class SideMenu implements OnInit {

    public managementPages = new MenuPages;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public auth: AuthService
    ) {}

    ngOnInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    public toggleDetails(page, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.managementPages.pages.forEach(page => {
            if (page.title === 'Finanças') {
                page.subPages.forEach(subPage => {
                    if (subPage.title == 'Previsões de Pagamento') {
                        subPage.url =  this.auth.user.profiles.some(
                            profile => profile.name === 'Previsões de Pagamento (Admin)')
                            ? '/payment-forecasts'
                            : '/payment-forecasts/list'
                    }
                })
            }
        })

		this.managementPages.pages.forEach (p => {
			if (p.title !== page.title) {
				p.showDetails = false;
			}
		});

        page.toggle();
	}
}
