import { User } from "pages/account/users/shared/user.model";
import { PreservationState } from "pages/finances/asset-management/preservation-states/shared/preservation-state.model";
import { File } from "pages/misc/files/shared/file.model";
import { Asset } from "pages/finances/asset-management/assets/shared/asset.model";
import { Validator } from "core/shared/validator";

export class MovableAsset extends Asset {
    
    public siengePurchaseOrderId: number = null;
    public preservationState: PreservationState = null;
    public model: string = null;
    public brand: string = null;
    public color: string = null;
    public series: string = null;
    public manufactureYear: string = null;
    public isItAsset: boolean = false;
    public user: User = null;
    public itemValue: number = null;
    public image:  File = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
        this.setGlpiTicket();
    }

    public get relationships() {
        let relationships = {
            preservationState: PreservationState,
            user:              User,
            image:             File,
        };

        return Object.assign(relationships, super.relationships);
    }

    public get http_data() {
        let data = super.http_data;

        if (this.isAdministrative) {
            data.siengePurchaseOrderId = this.siengePurchaseOrderId;
        }
        
        data.itemValue           = this.itemValue;
        data.preservationStateId = this.preservationState?.id;
        data.model               = this.model;
        data.brand               = this.brand;
        data.color               = this.color;
        data.manufactureYear     = this.manufactureYear;
        data.isItAsset           = this.isItAsset;
        data.series              = this.series;
        data.userId              = this.user?.id;

        if (this.image?.file) {
            data.image = this.image;
        }

        return data;
    }

    public get isMovable() {
        return true;
    }

    public get assetCategoryType(): string {
        return "movable";
    }

    public checkUserSelection() {
        if (!this.isItAsset) {
            this.user = null;
        }
    }

    public get fullDescription(): string {
        return this.description + ' ' + this.brand + ' ' + this.model + ' ' + this.color;
    }

    public get siengeInfo(): string {
        if (this.isAdministrative) {
            return 'Pedido de Compra ' + this.siengePurchaseOrderId;
        }

        return 'Título ' + this.siengeBillId;
    }

    public validate() {
        let validator = new Validator;

        validator.validateRequired(this.costCenter, 'Centro de Custo / Obra');

        if (this.isAdministrative) {
            validator.validateRequired(this.siengePurchaseOrderId, 'Número do Pedido de Compra Sienge');
        } else {
            validator.validateRequired(this.siengeBillId, 'Número do Título Sienge');
        }

        if (this.notAsset) {
            return true;
        }

        validator.validateRequired(this.itemValue, 'Preço unitário do ativo');
        
        if (this.isAdministrative) {
            validator.validateRequired(this.value, 'Valor usado no Pedido de Compra');
        }
        
        validator.validateRequired(this.preservationState, 'Estado de Conservação');
        validator.validateRequired(this.description, 'Descritivo do bem');
        validator.validateRequired(this.model, 'Modelo');
        validator.validateStringMaxSize(this.model, 50, 'Modelo');
        validator.validateRequired(this.brand, 'Marca');
        validator.validateStringMaxSize(this.brand, 50, 'Marca');
        validator.validateRequired(this.color, 'Cor');
        validator.validateStringMaxSize(this.color, 50, 'Cor');
        validator.validateRequired(this.manufactureYear, 'Ano de Fabricação');
        validator.validateStringSize(this.manufactureYear, 4, 'Ano de Fabricação');
        validator.validateRequired(this.image, 'Foto do Bem');

        if (!this.notAsset) {
            validator.validateStringMaxSize(this.comments, 500, 'Observações');
        }
    }
}
