import { Serializable } from "core/shared/serializable.model";

export class AssetCategory extends Serializable {

    public name: string = null;
    public description: string = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get isFixed() {
        return this.name === 'Bem Imóvel';
    }

    public get isMovable() {
        return this.name === 'Bem Móvel';
    }

    public get isGradual() {
        return this.name === 'Bem de Compra Gradual / CAPEX';
    }
}
