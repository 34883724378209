import { ChartData } from "core/components/charts/shared/chart-data.model";

export class BarChartDatum extends ChartData {

    public subData: BarChartDatum[] = [];

    constructor(
        categoryName: string,
        value: number,
        subData?: BarChartDatum[],
        color?: string,
        additionalLabel?: any
    ) {
        super(categoryName, value, color, null, null, null, additionalLabel);
        this.subData = subData;
    }
}
