import { ProductTipology } from 'pages/inputs/products/shared/product-tipology.model';
import { Serializable } from 'core/shared/serializable.model';
import { ProductBlock } from 'pages/inputs/products/shared/product-block.model';
import { Reservation } from 'pages/pv/dashboards/shared/reservation.model';
import { IptuPayment } from 'pages/incorporation/iptu-payment/shared/iptu-payment.model';

export class ProductUnit extends Serializable {

    public id: string = null;
    public name: string = null;
    public externalId: string = null;
    public hasIptuPayment: boolean = null;
    public realEstateRegistry: string = null;
    public evaluatedValue: number = 0;
    public saleValue: number = 0;
    public contractValue: number = 0;
    public status: number = 0;
    public productTipology: ProductTipology = null;
    public productBlock: ProductBlock = null;
    public productBlockName: string =  null;
    public productName: string =  null;

    public iptuPayment: IptuPayment = null;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    get relationships() {
        return {
            productTipology: ProductTipology,
            productBlock: ProductBlock,
            iptuPayment: IptuPayment
        };
    }

    public get statusName() {
        switch(this.status) {
            case 1:
                return 'Disponível';
            case 2:
                return 'Reservada';
            case 3:
                return 'Vendida';
            case 4:
                return 'Bloqueada';
            default:
                return this.status;
        }
    }

    public get statusColor() {
        switch(this.status) {
            case 1:
                return 'success';
            case 2:
                return 'warning';
            case 3:
                return 'medium';
            case 4:
                return 'danger';
            default:
                return 'primary';
        }
    }

    public get isAvailable() {
        return this.status !== 3 && this.status !== 4;
    }

    public searchByString(field: string, searchTerm: string = null) {
        if (!searchTerm) {
            return true;
        }
        
        if (this[field]) {
            return this[field].toLowerCase().includes(searchTerm.toLowerCase())
        }

        return false;
    }
}
