import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActionSheetController, MenuController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthService } from 'pages/account/auth/shared/auth.service';
import { LoaderService } from 'core/shared/loader.service';
import { TitleService } from 'core/shared/title.service';
import { PersonProfile } from 'pages/inputs/people/components/person-profile/person-profile';
import { CreateModal } from 'pages/misc/feedback/create/create.modal';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewChecked {
	public loading: boolean = false;
	public version: string = environment.version;
	public environment: string = environment.environment;

	constructor(
		public actionSheetController: ActionSheetController,
		public menuController: MenuController,
		public modalController: ModalController,
		public checkupController: ActionSheetController,
		public auth: AuthService,
		public loader: LoaderService,
		public titleService: TitleService,
		public router: Router,
	) { }

	ngAfterViewChecked() {
		this.updateLogo();
		this.updateBottomLogo();
	}

	private updateLogo() {
		const img = document.querySelector('#logo img');
		if (img) {
			const updatedSrc = this.verifyThemeForIcon();
			if (img.getAttribute('src') !== updatedSrc) {
				img.setAttribute('src', updatedSrc);
			}
		}
	}

	private updateBottomLogo() {
		const img = document.querySelector('.splash-img');
		if (img) {
			const updatedSrc = this.verifyThemeForIcon();
			if (img.getAttribute('src') !== updatedSrc) {
				img.setAttribute('src', updatedSrc);
			}
		}
	}

	async showUserProfile(identifier: string) {

		const modal = await this.modalController.create({
			component: PersonProfile,
			cssClass: 'person-profile-modal',
			componentProps: {
				id: identifier
			}
		});
		await modal.present();
	}

	async feedbackForm() {
		const modal = await this.modalController.create({
			component: CreateModal,
			cssClass: 'compact-modal feedback-modal'
		});

		await modal.present();
	}

	verifyThemeForIcon(): string {
		return document.body.getAttribute('color-theme') === 'light'
			? '../../assets/logo-prestes-light.png' :
			'../../assets/logo-prestes-dark.png';
	}
}

