import { ProductStage } from 'pages/inputs/products/shared/product-stage.model';
import { Serializable } from 'core/shared/serializable.model';
import { City } from 'pages/inputs/cities/shared/city.model';
import { ProductUnit } from 'pages/inputs/products/shared/product-unit.model';
import { ChartData } from 'core/components/charts/shared/chart-data.model';
import { IndividualGoal } from 'pages/pv/goals/shared/individual-goal.model';
import { ContractBalance } from 'pages/pv/contract-balances/shared/contract-balance.model';
import { ProductTipology } from 'pages/inputs/products/shared/product-tipology.model';
import { BarChartData } from 'core/components/charts/shared/bar-chart-data.model';
import { BarChartDatum } from 'core/components/charts/shared/bar-chart-datum.model';
import { Summarizable } from 'pages/pv/summaries-screen/shared/summarizable.interface';
import { Reservation } from 'pages/pv/dashboards/shared/reservation.model';


export class Product extends Serializable implements Summarizable {
    public id: string = null;
    public name: string = null;
    public image: string = null;
    public active: boolean = null;
    public external_id: string = null;
    public city: City = null;
    public region: any = null;
    public selected: boolean = true;
    public reservations: Reservation[] = [];

    public productStages: ProductStage[] = [];
    public individualGoal: IndividualGoal = null;
    public contractBalance: ContractBalance = null;
    public productTipologies: ProductTipology[] = [];

    public averageTicket: number = 0;
    public averageEvaluation: number = 0;
    public totalSalesValue: number = 0;
    public totalUnits: number = 0;
    public availableUnits: number = 0;

    public iptuPaymentActive: number = 0;


    public simulationEnabled: boolean = false;

    public overallProgress: number = null;

    public constructionSteps: any[] = [];

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return {
            city: City,
            productStages: ProductStage,
            contractBalance: ContractBalance,
            productTipologies: ProductTipology,
        };
    }

    public get city_id() {
        return this.city?.id;
    }

    public get http_data() {
        return {
            active: this.active,
            simulationEnabled: this.simulationEnabled,
            iptuPaymentActive: this.iptuPaymentActive,
            productStages: this.productStages.map((stage) => stage.http_data),
            productTipologies: this.productTipologies.map(
                (tipology) => tipology.http_data
            ),
        };
    }

    public get unitPercentage() {
        let percentage = 0;

        if (this.availableUnits !== 0) {
            percentage = this.availableUnits / this.totalUnits;
        }

        return percentage * 100;
    }

    public get vgv() {
        return this.totalSalesValue;
    }

    public get imageUrl() {
        if (!this.image) {
            return 'https://s3.sa-east-1.amazonaws.com/ccmimobiliaria/empreendimentos/sD7hnDETnC.jpg';
        }

        return `https://prestes.cvcrm.com.br/api/get/imagens/empreendimentos_foto/x/x/${this.image}`;
    }

    public get units(): ProductUnit[] {
        const units = this.productStages.map(
            (productStages: ProductStage) => productStages.units
        );

        return [].concat.apply([], units);
    }

    public get stockVgv() {
        return this.availableProductUnits
            .map((unit) => unit.saleValue)
            .reduce((a, b) => a + b, 0);
    }

    public get availableProductUnits(): ProductUnit[] {
        const units = this.productStages.map(
            (productStages: ProductStage) => productStages.availableProductUnits
        );

        return [].concat.apply([], units);
    }

    public get availableProductUnitsCount(): number {
        return this.availableProductUnits.length;
    }

    public get availability() {
        return new BarChartData(
            'Unidades Disponíveis',
            this.productStages
                .filter((stage) => stage.productBlocks.length > 0)
                .map(
                    (productStage: ProductStage) =>
                        new BarChartDatum(
                            productStage.name,
                            productStage.availableProductUnitsCount
                        )
                )
        );
    }

    public get totalTipology() {
        return this.units
        .reduce((acc, elm) => {
            const existing = acc.find(
                (item) => item.id === elm.productTipology.id
            );

            if (existing) {
                existing.quantity++;
            } else {
                acc.push({
                    name: elm.productTipology.name,
                    id: elm.productTipology.id,
                    quantity: 1,
                });
            }

            return acc;
        }, [])
        .sort((a, b) => b.quantity - a.quantity);
    }

    public get totalTipologyChartData() {
        const arrData = this.totalTipology;

        return arrData.map(
            (elm) => new ChartData(elm.name, elm.quantity, '#044d41', 'Total')
        );
    }

    public get tipologyAvailabilityChartData() {
        const arrData = this.availableProductUnits
            .reduce((acc, elm) => {
                const existing = acc.find(
                    (item) => item.id === elm.productTipology.id
                );

                if (existing) {
                    existing.quantity++;
                } else {
                    acc.push({
                        name: elm.productTipology.name,
                        id: elm.productTipology.id,
                        quantity: 1,
                    });
                }

                return acc;
            }, [])
            .sort((a, b) => b.quantity - a.quantity);

        const chartData = this.totalTipology.map(item => {
            const found = arrData.find(elm => elm.name === item.name) ?? -1;

            item.quantity = found != -1 ? found.quantity : 0;

            return item;
        })

        return chartData.map(
            (elm) =>
                new ChartData(elm.name, elm.quantity, '#84e115', 'Disponíveis')
        );
    }

    public get vgvPerTipologyChartData() {
        const arrData = this.availableProductUnits
            .reduce((acc, elm) => {
                const existing = acc.find(
                    (item) => item.id === elm.productTipology.id
                );

                if (existing) {
                    existing.value += elm.saleValue;
                    existing.quantity++;
                } else {
                    acc.push({
                        name: elm.productTipology.name,
                        id: elm.productTipology.id,
                        value: elm.saleValue,
                        quantity: 1,
                    });
                }

                return acc;
            }, [])
            .sort((a, b) => b.quantity - a.quantity);

            const chartData = this.totalTipology.map(item => {
                const found = arrData.find(elm => elm.name === item.name) ?? -1;

                item.value = found != -1 ? found.value : 0;
                item.quantity = found != -1 ? found.quantity : 0;

                return item;
            })

        return chartData.map(
            (elm) =>
                new ChartData(elm.name, elm.value, '#e1da15', 'VGV em Estoque', 'line', 'R$')
        );
    }
}
