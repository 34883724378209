import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { ScrollbarThemeModule } from 'core/directives/scrollbar-theme.directive';
import { PermissionModule } from 'pages/account/permissions/shared/permission.module';
import { PipeModule } from 'core/pipes/pipe.module';
import { PermissionGuard } from 'pages/account/auth/shared/permission.guard';
import { AuthGuard } from 'pages/account/auth/shared/auth.guard';
import { ReportCreatePage } from 'pages/pv/reports/create/create.page';

const routes: Routes = [
    {
        path: '',
        component: ReportCreatePage,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
            title: 'Relatórios',
            permissions: [
                ['view', 'pv_reports']
            ]
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        IonicModule,
        PipeModule,
        PermissionModule,
        IonicSelectableModule,
        ScrollbarThemeModule
    ],
    declarations: [
        ReportCreatePage
    ],
})
export class ReportModule { }
