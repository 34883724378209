import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { ToastService } from 'core/shared/toast.service';
import { FeedbackCategory } from 'pages/misc/feedback/shared/feedback-category.model';
import { Feedback } from 'pages/misc/feedback/shared/feedback.model';
import { FeedbackService } from 'pages/misc/feedback/shared/feedback.service';

@Component({
    templateUrl: './create.modal.html',
    styleUrls: ['./create.modal.scss'],
})
export class CreateModal implements OnInit {

    public feedback: Feedback = new Feedback();

    public fGroup: FormGroup;

    public feedbackCategories: FeedbackCategory[] = [];

    public categoriesByModule: FeedbackCategory[] = [];

    public canExecuteAction: boolean = true;

    public feedbackModules: string[] = []

    public selectedModule: string = null

    public glpiTicketIdUrl: string = null

    public layoutAfterSending : boolean = false

    constructor(
        private service: FeedbackService,
        private modalController: ModalController,
        private toastService: ToastService,
        private fBuilder: FormBuilder,
        private alertController: AlertController) {

        this.fGroup = this.fBuilder.group({
            title: [this.feedback.title, Validators.compose(
                [
                    Validators.required,
                    Validators.maxLength(200)
                ]
            )],
            description: [this.feedback.description, Validators.compose(
                [
                    Validators.required,
                    Validators.maxLength(500)
                ]
            )],
            category: [this.feedback.feedbackCategoryId, Validators.compose(
                [
                    Validators.required
                ]
            )],
            module: [this.feedback.module, Validators.compose(
                [
                    Validators.required
                ]
            )]
        });
    }

    ngOnInit() {
        //
    }

    ionViewWillEnter() {
        this.feedback = new Feedback();
        this.getFeedbackCategories();



    }

    public get title() {
        return this.fGroup.get('title');
    }

    public get description() {
        return this.fGroup.get('description');
    }

    public get category() {
        return this.fGroup.get('category');
    }

    public get module() {
        return this.fGroup.get('module')
    }

    public async dismiss() {
        this.canExecuteAction = true;
        await this.modalController.dismiss();

    }

    public async getFeedbackCategories() {
        const response = await this.service.getFeedbackCategories();

        const flattenedArray = response.data.feedbackCategories.flat();
        this.feedbackCategories = await FeedbackCategory.fromArray(flattenedArray);

        this.separatedForModules()

    }

    public separatedForModules() {
        const uniqueModulesSet = new Set<string>();

        this.feedbackCategories.forEach(category => {
            uniqueModulesSet.add(category.module);
        });

        this.feedbackModules = Array.from(uniqueModulesSet);
    }

    public updateCategoriesByModule(module: string): FeedbackCategory[] {

        this.feedback.feedbackCategoryId = null;
        this.categoriesByModule = [];

        this.feedbackCategories.forEach(category => {


            if (category.module.trim() === module.trim()) {
                this.categoriesByModule.push(category)
            }
        });

        return this.categoriesByModule
    }

    public save() {

        if (this.fGroup.invalid) {

            if (this.title.invalid) {
                if (this.title.errors?.required) {
                    this.toastService.present('Por favor insira um título.', 'warning');
                    return;
                }
                if (this.title.errors?.maxlength) {
                    this.toastService.present('A mensagem deve conter até 200 caracteres', 'warning');
                    return;
                }
            }
            if (this.module.invalid) {
                if (this.module.errors?.required) {
                    this.toastService.present('Por favor selecione uma módulo.', 'warning');
                    return;
                }
            }
            if (this.category.invalid) {
                if (this.category.errors?.required) {
                    this.toastService.present('Por favor selecione uma categoria.', 'warning');
                    return;
                }
            }
            if (this.description.invalid) {

                if (this.description.errors?.required) {
                    this.toastService.present('Por favor insira sua mensagem.', 'warning');
                    return;
                }
                if (this.description.errors?.maxlength) {
                    this.toastService.present('A mensagem deve conter até 500 caracteres.', 'warning');
                    return;
                }
            }
        }

        this.canExecuteAction = false

        this.feedback.title = this.title.value;
        this.feedback.description = this.description.value;
        this.feedback.feedbackCategoryId = this.feedback.feedbackCategoryId;

        this.service.store(this.feedback)
            .then((feedbackResponse: Feedback) => {
                this.glpiTicketIdUrl = feedbackResponse.glpiTicketUrl;
                this.layoutAfterSending = true;
            })

    }

    public newFeedbackCreate(){
        this.layoutAfterSending = false;
        this.canExecuteAction = true;
        this.feedback = new Feedback();
        this.feedbackModules = [];
        this.categoriesByModule = [];
        this.selectedModule = null;
        this.getFeedbackCategories();
    }

    

   

   
      



}



