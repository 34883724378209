import { Serializable } from "core/shared/serializable.model";
import { User } from "pages/account/users/shared/user.model";
import { AssetCategory } from "pages/finances/asset-management/asset-categories/shared/asset-category.model";
import { AssetFactory } from "pages/finances/asset-management/assets/shared/asset.factory";
import { Asset } from "pages/finances/asset-management/assets/shared/asset.model";
import { Company } from "pages/inputs/companies/shared/company.model";
import { CostCenter } from "pages/inputs/cost-centers/shared/cost-center.model";
import { PaymentMethod } from "pages/inputs/payment-methods/shared/payment-method.model";
import { Conversation } from "pages/misc/conversations/shared/conversation.model";
import { File } from "pages/misc/files/shared/file.model";

export class AssetPreRegistration extends Serializable {

    public sequentialId: number = null;
    public company: Company = null;
    public costCenter: CostCenter = null;
    public assetCategory: AssetCategory = null;
    public paymentMethod: PaymentMethod = null;
    public nfeNumber: string = null;
    public nfe: any = null;
    public boletos: any = null;
    public contract: any = null;
    public value: number = null;
    public siengeContractId: number = null;
    public createdAt: string = null;

    public creator: User = null;
    public conversation: Conversation = null;

    public assets: Asset[] = [];

    constructor(data: any = {}) {
        super();
        this.serialize(data);
        this.serializeAssets(data);
    }

    private serializeAssets(data: any) {
        if (!data.assets || !Array.isArray(data.assets)) {
            return;
        }

        this.assets = data.assets.map(
            (asset) => new AssetFactory(asset).create()
        );
    }

    public get relationships() {
        return {
            company:       Company,
            costCenter:    CostCenter,
            assetCategory: AssetCategory,
            paymentMethod: PaymentMethod,
            nfe: File,
            boletos: File,
            contract: File,
            creator: User,
            conversation: Conversation
        };
    }

    public get isMovable() {
        return this.assetCategory?.name === 'Bem Móvel';
    }

    public get isFixed() {
        return this.assetCategory?.name === 'Bem Imóvel';
    }

    public get isGradual() {
        return this.assetCategory?.name === 'Bem de Compra Gradual / CAPEX';
    }

    public get assetCategoryType() {
        if (this.isMovable) {
            return 'movable';
        } else if (this.isFixed) {
            return 'fixed';
        } else if (this.isGradual) {
            return 'gradual';
        } else {
            return null;
        }
    }

    public get paymentMethodType() {
        if (!this.paymentMethod) {
            return null;
        }
        
        if (this.paymentMethod.name === 'Transferência Bancária') {
            return 'bankTransfer';
        } else if (this.paymentMethod.name === 'Boleto Bancário') {
            return 'bankSlip';
        } else {
            return null;
        }
    }

    public get isAdministrative() {
        return this.company?.administrative;
    }

    public get isConstruction() {
        return !this.company?.administrative;
    }

    public get hasAssets() {
        return this.assets.length > 0;
    }

    public get hasActiveForm() {
        return this.assets.some( asset => asset.activeForm );
    }

    public async createAsset(): Promise<any> {
        return new Promise((resolve) => resolve(
            new AssetFactory({
                company:       this.company,
                costCenter:    this.costCenter,
                assetCategory: this.assetCategory,
            }).create()
        ));
    }

    public async addAsset(asset: Asset): Promise<Asset> {
        return new Promise((resolve) => {
            this.assets.push(asset);

            return resolve(asset);
        });
    }

    public replaceAsset(index: number, asset: Asset): Promise<number> {
        return new Promise((resolve) => {
            this.assets[index] = asset;

            return resolve(index);
        });
    }

    public removeAsset(index: number): Promise<boolean> {
        return new Promise((resolve) => {
            this.assets.splice(index, 1);

            return resolve(true);
        });
    }

    public wipeCostCenter() {
        this.costCenter = null;
    }

    public changePaymentMethod() {
        this.boletos = [];
    }

    public get http_data() {
        return {
            companyId:         this.company?.id,
            companyType:       this.company?.type,
            costCenterId:      this.costCenter?.id,
            assetCategoryType: this.assetCategoryType,
            assetCategoryId:   this.assetCategory?.id,
            nfe:               this.isMovable ? this.nfe : null,
            boletos:           this.paymentMethodType === 'bankSlip' ? this.boletos : null,
            contract:               this.isFixed ? this.contract : null,
            nfeNumber:         this.isMovable ? this.nfeNumber : null,
            value:             this.value,
            siengeContractId:  this.isFixed ? this.siengeContractId : null,
            paymentMethodId:   this.paymentMethod?.id,
            paymentMethodType: this.paymentMethodType,
            assets:            this.assets.map((asset: Asset) => asset.http_data)
        };
    }
}
