import { Component, Input } from "@angular/core";


@Component({
    selector: 'troncudo-table',
    templateUrl: './troncudo-table.html',
    styleUrls: ['./troncudo-table.scss']
})
export class TroncudoTableComponent {

    @Input('headers') headers: string[] = [];
    @Input('data') data?: any[] = [];
    @Input('loading') loading?: boolean = false;

}