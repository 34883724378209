import { BarChartDatum } from "core/components/charts/shared/bar-chart-datum.model";
import { ChartData } from "core/components/charts/shared/chart-data.model";

export class BarChartData extends ChartData {
    public seriesName: string;

    public subData: BarChartDatum[] = [];

    constructor(
        seriesName: string,
        subData: BarChartDatum[],
    ) {
        super('', 0);
        this.seriesName = seriesName;

        this.subData = subData;
    }
}
