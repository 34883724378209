import { Serializable } from "core/shared/serializable.model";
import { CommissionRuleCondition } from "pages/automations/commission-payment-automation/shared/comission-rule-condition.model";

export class CommissionRule extends Serializable {

    public id: string = null;
    public name: string = null;

    public conditions: CommissionRuleCondition[] = [];

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    get relationships() {
        return {
            conditions: CommissionRuleCondition
        };
    }

    public addCondition() {
        this.conditions.push(new CommissionRuleCondition);
    }

    public removeCondition(index) {
        this.conditions.splice(index, 1);
    }

    public get http_data() {
        return {
            name:       this.name,
            conditions: this.conditions.map(condition => condition.http_data)
        };
    }
}