import { Serializable } from 'core/shared/serializable.model';
import { ProductUnit } from 'pages/inputs/products/shared/product-unit.model';

export class ProductBlock extends Serializable {

    public id: string = null;
    public name: string = null;
    public external_id: string = null;
    public productUnits: Array<ProductUnit> = [];

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    get relationships() {
        return {
            productUnits: ProductUnit
        };
    }

    public get availableProductUnits(): ProductUnit[] {
        return this.productUnits.filter(
            (productUnit: ProductUnit) => productUnit.isAvailable
        );
    }

    public get availableProductUnitsCount(): number {
        return this.availableProductUnits.length;
    }
}
