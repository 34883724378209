import { Serializable } from "core/shared/serializable.model";

export class GlpiTicket extends Serializable {

    public id: number = null;
    public title: string = null;
    public description: string = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get url() {
        return `https://helpdesk.prestes.com/front/ticket.form.php?id=${this.id}`;
    }
}