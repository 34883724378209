import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    public monthNames: Array<String> = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ];

    public update: string = 'Salvar Alterações';

    public externalInfo: string = 'Esta informação é sincronizada de fontes externas';
}