import { User } from 'pages/account/users/shared/user.model';
import { LeadSnapshot } from './lead-snapshot.model';
import { DateTime } from 'luxon';
import { Checkup } from 'pages/pv/checkups/shared/checkup.model';
import { Serializable } from 'core/shared/serializable.model';
import { Product } from 'pages/inputs/products/shared/product.model';
import { ReportableLeadSnapshot } from './reportable-lead-snapshot.model';

export class Lead extends Serializable {

    public id: string = null;
    public customer_name: string = null;
    public external_id: string = null;
    public products: Array<Product> = [];
    public checkup: Checkup = null;
    public mode: string = 'daily';
    public conversions: Array<any> = [];
    public full_conversions: Array<any> = [];
    public person_id: string = null;
    public date: string = null;
    public hoursToAccomplishSelfAnalysis = 25 ;

    public lead_snapshots: Array<LeadSnapshot> = [];
    public reportable_lead_snapshots: Array<ReportableLeadSnapshot> = [];

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get relationships(): Object {
        return {
            products: Product,
            checkup: Checkup,
            lead_snapshots: LeadSnapshot,
            reportable_lead_snapshots: ReportableLeadSnapshot,
        };
    }

    public changeMode() {
        this.mode = this.mode === 'full' ? 'daily' : 'full';
    }

    public get product_names() {
        return this.products.map( product => product.name ).join(', ');
    }

    public get cv_url() {
        return `https://prestes.cvcrm.com.br/gestor/comercial/leads/${this.external_id}/administrar`;
    }

    /**
     * Retorna se data atual está dentro do tempo estipulado para auto-avaliação
     */
    public get isCheckupAvaiable(): boolean {
        const isAvaiable = this.timeSinceVisit.hours < this.hoursToAccomplishSelfAnalysis;

        return isAvaiable;
    }

    public get visitDateTime() {
        return DateTime.fromFormat(this.date, 'yyyy-MM-dd HH:mm:ss');
    }

    public get timeSinceVisit() {
        return DateTime.now().diff(this.visitDateTime, ['hours', 'minutes', 'seconds']).values;
    }
    /**
     * @return tempo disponível para executar auto-avaliação formatado 'HH'h'mm'
     */
    public get timeAvaiableToCheckupFormatted(): string {
        const deadline = this.visitDateTime.plus({ hours: this.hoursToAccomplishSelfAnalysis });

        const differenceFromNowObject = deadline.diffNow(['hours', 'minutes', 'seconds']).values;

        const hours = Math.abs(differenceFromNowObject.hours).toString();
        const minutes = Math.abs(differenceFromNowObject.minutes).toString();

        const timeAvaiableFormatted = hours.padStart(2,'0') + 'h' + minutes.padStart(2,'0');

        return timeAvaiableFormatted;
    }

    public getCvUrl(user: User) {
        if (user.accessType === 'realtor') {
            return `https://prestes.cvcrm.com.br/gestor/comercial/leads/${this.external_id}/administrar`;
        }

        return `https://prestes.cvcrm.com.br/gestor/comercial/leads/${this.external_id}/administrar`;
    }
}
