import { Injectable } from '@angular/core';
import { BaseService } from 'core/shared/base.service';
import { PermissionCategory } from 'pages/account/permissions/shared/permission-category.model';
import { BaseResponse } from 'core/shared/base-response.interface';
import { User } from 'pages/account/users/shared/user.model';

@Injectable({
    providedIn: 'root'
})
export class PermissionService extends BaseService {

    protected url: string = 'api/permission-categories';

    /**
     * `GET /api/permission-categories`
     * 
     * Get PermissionCategories
     */
    public async get(queryParams: string = ''): Promise<PermissionCategory[]> {
        return super.handleGet(queryParams).then(
            (response: BaseResponse) => response.data.permissionCategories.map(
                permissionCategory => new PermissionCategory(permissionCategory)
            )
        );
    }

    /**
     * Verifica se o usuário informado tem a(s) permissão(ões) informada(s)
     */
    public userCan(
        user: User,
        permissions: Array<string | Array<string | Array<string>>>
    ): boolean {
        // Se for array multi-nível
        if (permissions[0].constructor === Array) {
            return permissions.map((permission: Array<string | Array<string>>) => {
                // Se for permissão multi-nível (aplica regra ou)
                if (permission[0].constructor === Array) {
                    return permission.map(p => user.can(p[0], p[1])).some(item => item === true);
                } else {
                    const type: any = permission[0];
                    const category: any = permission[1];

                    return user.can(type, category);
                }
                
            }).every(item => item === true);

        } else {
            const type: any = permissions[0];
            const category: any = permissions[1];

            return user.can(type, category);
        }
    }
}
