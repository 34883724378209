import { Injectable } from '@angular/core';
import { BaseService } from 'core/shared/base.service';
import { BaseResponse } from 'core/shared/base-response.interface';
import { AssetPreRegistration } from 'pages/finances/asset-management/asset-pre-registrations/shared/asset-pre-registration.model';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { Asset } from '../../assets/shared/asset.model';
import { AssetFactory } from 'pages/finances/asset-management/assets/shared/asset.factory';

@Injectable({
    providedIn: 'root'
})
export class AssetPreRegistrationService extends BaseService {

    protected url: string = 'api/asset-pre-registrations';

    /**
     * `GET /api/asset-pre-registrations`
     *
     * GetAssetPreRegistrations
     */
    public async get(): Promise<AssetPreRegistration[]> {
        return super.handleGet().then(
            (response: BaseResponse) =>
                response.data.assetPreRegistrations.map(item => new AssetPreRegistration(item))
        );
    }

    /**
     * `GET /api/asset-pre-registrations/{assetPreRegistrationId}`
     *
     * GetAssetPreRegistrationById
     */
    public async getById(assetPreRegistrationId: string): Promise<AssetPreRegistration> {
        return super.handleGetById(assetPreRegistrationId).then(
            (response: BaseResponse) => new AssetPreRegistration(response.data.assetPreRegistration)
        );
    }

    /**
     * `GET /api/asset-pre-registrations/{assetPreRegistrationId}`
     *
     * GetAssetPreRegistrationById
     */
    public download(fileName: string): Observable<Blob> {
        return this.http.get(this.buildUrl(`/download/${fileName}`), { responseType: 'blob'});

    }

    /**
     * `POST /api/asset-pre-registrations`
     *
     * StoreAssetPreRegistrations
     */
    public async store(assetPreRegistration: AssetPreRegistration): Promise<AssetPreRegistration> {
        let headers = new HttpHeaders({
            'Access-Control-Allow-Origin': '*'});

        return this.handleBaseResponse(
            this.http.post(this.buildUrl('/' + assetPreRegistration.assetCategoryType), this.jsonToFormData(assetPreRegistration.http_data), {headers: headers}
            ).toPromise()
        ).then(
            (response: BaseResponse) => {
                return new AssetPreRegistration(response.data.assetPreRegistration);
            }
        );
    }

    /**
     * `PUT /api/asset-pre-registrations/{assetPreRegistrationId}/edit-asset/{assetNumber}`
     *
     * Update Asset
     */
    public async updateAssetByAssetNumber(assetPreRegistration: AssetPreRegistration, asset: Asset): Promise<Asset> {
        return this.handleBaseResponse(
            this.http.post(this.buildUrl(`/${assetPreRegistration.id}/edit-asset/${asset.assetNumber}?_method=PUT`), this.jsonToFormData(asset.http_data)).toPromise()
        ).then(
            (response: BaseResponse) => new AssetFactory(response.data.asset).create()
        );
    }


    /**
     * `PUT /api/asset-pre-registrations/{assetId}}/update-asset-number`
     *
     * UpdateAssetNumber
     */
    public async updateAssetNumber(asset: Asset): Promise<Asset> {
        return this.handleBaseResponse(
            this.http.put(this.buildUrl(`/${asset.id}/update-asset-number`), {
                assetNumber: asset.assetNumber
            }).toPromise()
        ).then(
            (response: BaseResponse) => new AssetFactory(response.data.asset).create()
        );
    }

    /**
     * `PUT /api/asset-pre-registrations/{assetId}/update-sienge-bill-id`
     *
     * UpdateSiengeBillId
     */
    public async updateSiengeBillId(asset: Asset): Promise<Asset> {
        return this.handleBaseResponse(
            this.http.put(this.buildUrl(`/${asset.id}/update-sienge-bill-id`), {
                siengeBillId: asset.siengeBillId
            }).toPromise()
        ).then(
            (response: BaseResponse) => new AssetFactory(response.data.asset).create()
        );
    }

    /**
     * `PUT /api/asset-pre-registrations/{assetPreRegistrationId}/assets/{assetId}/deny`
     *
     * DenyAsset
     */
    public async denyAsset(
        assetPreRegistrationId: string, 
        assetId: string, 
        denialJustification: string
    ): Promise<Asset> {
        return this.handleBaseResponse(
            this.http.put(this.buildUrl(`/${assetPreRegistrationId}/assets/${assetId}/deny`), {
                denialJustification: denialJustification
            }).toPromise()
        ).then(
            (response: BaseResponse) => new AssetFactory(response.data.asset).create()
        );
    }
}
