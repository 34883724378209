import { BaseService } from 'core/shared/base.service';
import { Injectable } from '@angular/core';
import { BaseResponse } from 'core/shared/base-response.interface';
import { Feedback } from 'pages/misc/feedback/shared/feedback.model';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService extends BaseService {

    protected url: string = 'api/feedbacks';

    /**
     * `POST /api/feedbacks`
     * 
     * Store Feedback
     */
    public async store(feedback: Feedback): Promise<Feedback> {
    
        const baseResponse = this.handleBaseResponse(
            this.http.post((`${this.apiUrl}/feedbacks`), feedback).toPromise()
        ).then((response: BaseResponse) => new Feedback(response.data.feedback));
    
        return baseResponse
        
    }
    

    /**
     * `GET /api/feedback-categories`
     * 
     * Get FeedbackCategories
     */
    public getFeedbackCategories(): Promise<{ data: any }> {
        return this.http.get<{ data: any }>(`${this.apiUrl}/feedbacks/categories`).toPromise();
    }
}
