import { Serializable } from 'core/shared/serializable.model';
import { DateTime } from 'luxon';

export class CheckupQuestion extends Serializable {
    public id:   string = null; 

    public statement: string = null;

    public total_points: number = null;

    public order: number = null;

    public score: number = null;

    public isChecked: number = 0;

    public time: Date = null;

    constructor(data:any={}) {
        super();
        this.serialize(data);
    }

    get http_data() {
        return {
            id:           this.id,
            statement:    this.statement,
            total_points: this.total_points,
            order:        this.order,
            score:        this.score,
            time:         this.time,
        };
    }

    get valid() {
        return !(this.score === null || isNaN(this.score));
    }

    get time_datetime() {
        return this.time ?
                DateTime .fromFormat(this.time, 'yyyy-MM-dd hh:mm:ss').toFormat('dd/MM/yyyy \'as\' hh:mm:ss') : 
                null;
    }
}
