import { IPersonAvatar } from 'pages/inputs/people/components/person-avatar/person.avatar.interface';
import { Person } from 'pages/inputs/people/shared/person.model';
import { IndividualGoal } from 'pages/pv/goals/shared/individual-goal.model';
import { DashCardEntity } from 'pages/pv/dashboards/components/dash-card/dash-card-entity.interface';
import { PerformanceInfo } from 'pages/pv/dashboards/components/dash-card/performance-info.model';
import { TrainingInfo } from 'pages/pv/dashboards/components/dash-card/training-info.model';
import { CheckupInfo } from 'pages/pv/dashboards/components/dash-card/checkup-info.model';
import { SalesInfo } from 'pages/pv/dashboards/components/dash-card/sales-info.model';
import { SalesTeam } from 'pages/pv/sales-teams/shared/sales-team.model';
import { ThermometerInfo } from 'pages/pv/dashboards/components/dash-card/thermometer-info.model';

/**
 * Classe representanto registros de corretores.
 */
export class Realtor extends Person implements DashCardEntity, IPersonAvatar {
    public id: string = null;
    public individual_goal: IndividualGoal = null;
    public sales_teams: Array<SalesTeam> = [];
    public is_entry_month: boolean = false;
    public is_exit_month: boolean = false;
    public salesTeamId: string = null;

    public salesInfo: SalesInfo = new SalesInfo();
    public checkupInfo: CheckupInfo = new CheckupInfo();
    public trainingInfo: TrainingInfo = new TrainingInfo();
    public performanceInfo: PerformanceInfo = new PerformanceInfo();
    thermometerInfo: ThermometerInfo;

    public sales_team: SalesTeam = null;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return Object.assign(super.relationships, {
            sales_team:  SalesTeam,
            individual_goal:  IndividualGoal,
        });
    }

    public get maturityColor(): string {
        if (this.maturity === 'Iniciante') {
            return 'avatar-iniciante';
        } else if (this.maturity === 'Júnior') {
            return 'avatar-junior';
        } else if (this.maturity === 'Pleno') {
            return 'avatar-pleno';
        } else if (this.maturity === 'Sênior') {
            return 'avatar-senior';
        } else {
            return 'avatar-padrao';
        }
    }

    public get maturityBadge(): string {
        switch (this.maturity) {
            case 'Iniciante':
                return 'light';
            case 'Júnior':
                return 'prestes-green';
            case 'Pleno':
                return 'prestes-blue';
            case 'Sênior':
                return 'prestes-yellow';
            default:
                return 'primary';
        }
    }

    public get subtitle() {
        return '';
    }

    public get entityId() {
        return this.id;
    }

    public get full_details() {
        return `${this.name} - ${this.document} (${this.email})`;
    }

    public get title() {
        return this.name;
    }

    public get singular() {
        return 'realtor';
    }

    public get plural() {
        return 'realtors';
    }
}
