import { Serializable } from 'core/shared/serializable.model';

export class City extends Serializable {

    public id: string = null;
    public name: string = null;
    public ibge: string = null;
    public cvaCeil: number = null;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get http_data() {
        return {
            ibge:    this.ibge,
            cvaCeil: this.cvaCeil
        };
    }
}
