import { Serializable } from 'core/shared/serializable.model';
import { environment } from '../../../../../environments/environment';

export class File extends Serializable {
    public id: string = null;
    public name: string = null;
    public path: string = null;
    public disk: string = null;
    public fileable: any = null;
    public fileableType: string = null;
    public fileableId: string = null;
    public category: string = null;
    public size?: number = null;
    public mimeType?: string = null;

    public file: any = null;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get urlImage(): string {
        return this.path && this.fileableType && this.category
            ? `${environment.url}/images/${this.fileableType.replace(
                  /_/g,
                  '-'
              )}/${this.category.replace(
                /_/g,
                '-')}/${this.path}`
            : null;
    }

    public get formattedFileSize(): string {
        return "";
        if (this.size === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = 2 <= 0 ? 0 : 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(this.size) / Math.log(k));
        return (
            parseFloat((this.size / Math.pow(k, i)).toFixed(dm)) +
            ' ' +
            sizes[i]
        );
    }

    public get canDownload(): boolean {
        return this.path && this.fileableType && this.category ? true : false;
    }
}
