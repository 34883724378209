import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BasePage } from 'core/components/pages/base/base.page';
import { Tab } from 'core/components/pages/shared/tab.model';

@Component({
    selector: 'index-page',
    templateUrl: './index.page.html',
    styleUrls: ['./index.page.scss'],
})
export class IndexPage extends BasePage {
    //Header
    @Output('onSearch') private onSearch: EventEmitter<string> = new EventEmitter<string>();
    @Input('search') public search?: boolean = true;
    @Input('backButton') public backButton?: string = null;
    public showSearch: boolean = false;
    public searchValue: string = null;

    //Content
    public skeletons: number[] = Array(15).fill(15).map((x, i)=> i);

    public toggleSearch() {
        this.showSearch = !this.showSearch;

        if (!this.showSearch) {
            this.searchValue = null;
            this.applySearch();
        }
    }

    public applySearch() {
        this.onSearch.emit(this.searchValue);
    }
}
