import { Checkup } from "pages/pv/checkups/shared/checkup.model";
import { Lead } from "pages/pv/leads/shared/lead.model";

export class CheckupInfo {
    public behavioralCheckup: Checkup;

    public behavioralHistory: Checkup[];

    public technicalCheckup: Checkup;

    public technicalHistory: Checkup[];

    public selfAnalysisCompleted: number = 0;
    
    public selfAnalysisNeeded: number = 0;

    public visits: Array<any> = [];

    constructor(data: any = null) {
        this.update(data);
    }

    public update(data: any = null) {
        if (!data) {
            return;
        }

        this.setBehavioralCheckup(data.behavioralCheckup);
        this.setTechnicalCheckup(data.technicalCheckup);
        this.setBehavioralHistory(data.behavioralHistory);
        this.setTechnicalHistory(data.technicalHistory);
        this.setSelfAnalysisCompleted(data.selfAnalysisCompleted);
        this.setSelfAnalysisNeeded(data.selfAnalysisNeeded);
        this.setVisits(data.visits);
    }

    private setBehavioralCheckup(data: any = null) {
        if (data === undefined) {
            return;
        }

        if (data) {
            this.behavioralCheckup = new Checkup(data);
        } else {
            this.behavioralCheckup = null;
        }
    }

    private setTechnicalCheckup(data: any = null) {
        if (data === undefined) {
            return;
        }

        if (data) {
            this.technicalCheckup = new Checkup(data);
        } else {
            this.technicalCheckup = null;
        }
    }

    private setBehavioralHistory(data: any[] = []) {
        if (data === undefined) {
            return;
        }

        if (data) {
            this.behavioralHistory = data.map ( checkup => new Checkup(checkup) );
        } else {
            this.behavioralHistory = [];
        }
    }

    private setTechnicalHistory(data: any[] = []) {
        if (data === undefined) {
            return;
        }

        if (data) {
            this.technicalHistory = data.map ( checkup => new Checkup(checkup) );
        } else {
            this.technicalHistory = [];
        }
    }

    private setSelfAnalysisCompleted(data: number = null) {
        if (data === undefined) {
            return;
        }
        if (data !== null) {
            this.selfAnalysisCompleted = data;
        }
    }

    private setSelfAnalysisNeeded(data: number = null) {
                if (data === undefined) {
            return;
        }
        if (data !== null) {
            this.selfAnalysisNeeded = data;
        }
    }

    public setVisits(visits: Array<any> = null) {
        if (visits === undefined) {
            return;
        }

        if (visits === null) {
            return;
        }

        this.visits = visits.map ( visit => new Lead(visit));
    }

    get selfAnalysisColor() {
        if (this.selfAnalysisNeeded <= 0) {
            return 'medium';
        }

        if (this.selfAnalysisCompleted == 0) {
            return 'danger';
        }

        if (this.selfAnalysisCompleted >= this.selfAnalysisNeeded) {
            return 'success';
        }

        if (this.selfAnalysisCompleted < this.selfAnalysisNeeded) {
            return 'warning';
        }

        return 'medium';
    }
}