import { DateTime } from 'luxon';
import { ChartData } from 'core/components/charts/shared/chart-data.model';
import { SalesFunnelStage } from 'pages/pv/sales-funnels/shared/sales-funnel-stage.model';
import { SalesTeam } from 'pages/pv/sales-teams/shared/sales-team.model';
import { Realtor } from 'pages/inputs/people/shared/realtor.model';
import { Lead } from 'pages/pv/leads/shared/lead.model';
import { Serializable } from 'core/shared/serializable.model';
import { Product } from 'pages/inputs/products/shared/product.model';


export class ReportableLeadSnapshot extends Serializable {

    public id: string = null;
    public date: string = null;
    public lead: Lead = null;
    public person: Realtor = null;
    public sales_team: SalesTeam = null;
    public products: Array<Product> = [];
    public sales_funnel_stages: Array<SalesFunnelStage> = [];

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get relationships(): Object {
        return {
            lead: Lead,
            person: Realtor,
            sales_team: SalesTeam,
            sales_funnel_stages: SalesFunnelStage,
            products: Product
        };
    }

    public get formattedDate() {
        return DateTime.fromFormat(this.date, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy');
    }

    public get funnel() {
        return this.sales_funnel_stages.map (stage =>
            new ChartData(stage.name, stage.pivot.count)
        );
    }
}
