import { Serializable } from "core/shared/serializable.model";
import { Permission } from "pages/account/permissions/shared/permission.model";
import { User } from "pages/account/users/shared/user.model";

export class ApprovalFlowApprover extends Serializable {

    public order: number = null;
    public minimumValue: number = null;
    public approverConfigurableType: string = null;
    public approverConfigurable: User|Permission = null;

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        if (!this.approverConfigurableClass) {
            return {};
        }
        
        return {
            approverConfigurable: this.approverConfigurableClass
        };
    }

    public get approverConfigurableClass() {
        switch (this.approverConfigurableType) {
            case 'users':
                return User;
            case 'permissions':
                return Permission;
        }

        return null;
    }

    public get typeDescription() {
        switch (this.approverConfigurableType) {
            case 'users':
                return 'Usuário';
            case 'permissions':
                return 'Usuários com a permissão';
        }
    }

    public get http_data() {
        return {
            id:                       this.id,
            order:                    this.order,
            minimumValue:             this.minimumValue,
            approverConfigurableType: this.approverConfigurableType,
            approverConfigurableId:   this.approverConfigurable?.id
        };
    }
}