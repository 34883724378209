import { TypeProperties } from 'pages/pv/sales-teams/shared/sales-team.interface';
import { ExternalGoal } from 'pages/pv/goals/shared/external-goal.model';
import { PerformanceInfo } from 'pages/pv/dashboards/components/dash-card/performance-info.model';
import { TrainingInfo } from 'pages/pv/dashboards/components/dash-card/training-info.model';
import { CheckupInfo } from 'pages/pv/dashboards/components/dash-card/checkup-info.model';
import { SalesInfo } from 'pages/pv/dashboards/components/dash-card/sales-info.model';
import { Realtor } from 'pages/inputs/people/shared/realtor.model';
import { RegionalManager } from 'pages/inputs/people/shared/regional-manager.model';
import { DashCardEntity } from 'pages/pv/dashboards/components/dash-card/dash-card-entity.interface';
import { Serializable } from 'core/shared/serializable.model';
import { Manager } from 'pages/inputs/people/shared/manager.model';
import { ThermometerInfo } from 'pages/pv/dashboards/components/dash-card/thermometer-info.model';


export class SalesTeam extends Serializable implements DashCardEntity {
    public id: string = null;
    public name: string = null;
    public type: string = null;
    public comment: string = null;
    public manager: Manager = null;
    public external_id: any = null;
    public active: boolean | number = null;
    public regional_manager: RegionalManager = null;
    public realtors: Array<Realtor> = [];
    public people = [];
    public manager_id: string = null;
    public month: string = null;

    public salesInfo: SalesInfo = new SalesInfo();
    public checkupInfo: CheckupInfo = new CheckupInfo();
    public trainingInfo: TrainingInfo = new TrainingInfo();
    public performanceInfo: PerformanceInfo = new PerformanceInfo();
    thermometerInfo: ThermometerInfo;

    public individual_goal: ExternalGoal = new ExternalGoal();

    public selected = true;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return {
            manager: Manager,
            regional_manager: RegionalManager,
            realtors: Realtor,
            individual_goal: ExternalGoal,
        };
    }

    public get regional_manager_id() {
        return this.regional_manager?.id;
    }

    public get http_data() {
        return {
            manager_id: this.manager?.id,
            regional_manager_id: this.regional_manager?.id,
            comment: this.comment,
            type: this.type,
            active: this.active
        };
    }

    public get image() {
        if (this.manager) {
            if (this.manager.user) {
                return this.manager.user.image;
            }
        }

        return null;
    }

    public get maturityColor() {
        return 'avatar-padrao';
    }

    public get maturity() {
        return '';
    }

    public get subtitle() {
        if (this.type === 'external') {
            return this.manager?.name;
        }
        return this.name;
    }

    public get entityId() {
        return this.id;
    }

    public get title() {
        if (this.type === 'external') {
            return this.name;
        }

        return this.manager?.name;
    }

    public get typeProperties(): TypeProperties {
        return {
            label: this.type === 'external' ? 'Imobs' : 'Equipe Própria',
            color: this.type === 'external' ? 'prestes-dark-green' : 'prestes-green'
        };
    }

    public get singular() {
        return 'salesTeam';
    }

    public get plural() {
        return 'salesTeams';
    }
}
