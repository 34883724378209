import { Serializable } from "core/shared/serializable.model";
import { Manager } from "pages/inputs/people/shared/manager.model";
import { Product } from "pages/inputs/products/shared/product.model";
import { SalesTeam } from "pages/pv/sales-teams/shared/sales-team.model";

export class ContractBalance extends Serializable {
    public month: string = null;
    public value: number = null;
    public targetableType: string = null;
    public targetableId: string = null;
    public targetable: SalesTeam & Manager = null;
    public product: Product = null;

    constructor(data: any = {}) {
        super();
        super.serialize(data);
    }

    private get targetableTypeRelationship() {
        switch(this.targetableType) {
            case 'sales_teams':
                return SalesTeam;
            case 'managers':
                return Manager;
            default: 
                return null;
        };
    }

    protected get relationships() {
        return {
            targetable: this.targetableTypeRelationship,
            product: Product
        };
    }

    public get productId() {
        return this.product?.id;
    }

    public get http_data() {
        return {
            productId: this.productId,
            value:     this.value,
        };
    }
}