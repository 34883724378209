import { Component, Input } from '@angular/core';
import { AssetPreRegistrationService } from 'pages/finances/asset-management/asset-pre-registrations/shared/asset-pre-registration.service';
import { File } from 'pages/misc/files/shared/file.model';

@Component({
    selector: 'file-card',
    templateUrl: './file-card.component.html',
    styleUrls: ['./file-card.component.scss'],
})
export class FileCard {
    @Input() file: File;
    @Input() files: File[];

    constructor(
        private assetPreRegistationService: AssetPreRegistrationService
    ) {}

    public downloadFile() {
        return this.assetPreRegistationService
            .download(this.file.path)
            .subscribe((x) => {
                var newBlob = new Blob([x], { type: this.file.mimeType });
                const data = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                link.href = data;
                link.download = this.file.path;
                link.dispatchEvent(
                    new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window,
                    })
                );
                setTimeout(function () {
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
            });
    }

    public downloadFiles(file) {
        return this.assetPreRegistationService
            .download(file.path)
            .subscribe((x) => {
                var newBlob = new Blob([x], { type: file.mimeType });
                const data = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                link.href = data;
                link.download = file.path;
                link.dispatchEvent(
                    new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window,
                    })
                );
                setTimeout(function () {
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
            });
    }
}
