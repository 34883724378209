import { BaseModule } from 'core/shared/base.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PersonProfile } from 'pages/inputs/people/components/person-profile/person-profile';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        BaseModule,
    ],
    declarations: [
        PersonProfile
    ],
    exports: [
        PersonProfile,
    ],
    entryComponents: [
        PersonProfile
    ]
})
export class PersonProfileModule { }
