import { Serializable } from 'core/shared/serializable.model';

export class SurveyCategory  extends Serializable {
    public id:   string = null;

    public name: string = null;

    constructor(data: any={}) {
        super();
        this.serialize(data);
}

public get http_data() {
    return {
        id:   this.id,
        name: this.name
    };
}
}
