import { BaseService } from 'core/shared/base.service';
import { Injectable } from '@angular/core';
import { Person } from 'pages/inputs/people/shared/person.model';
import { BaseResponse } from 'core/shared/base-response.interface';
import { RegionalManager } from 'pages/inputs/people/shared/regional-manager.model';
import { Manager } from 'pages/inputs/people/shared/manager.model';
import { Realtor } from 'pages/inputs/people/shared/realtor.model';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PersonService extends BaseService {

    protected url: string = 'api/inputs/people';

    public getManagers$: Subject<void> = new Subject();

    /**
     * `GET /api/inputs/people`
     *
     * Get People
     */
    public async get(queryParams: string = ''): Promise<Person[]> {
        return super.handleGet(queryParams).then(
            (response: BaseResponse) => response.data.people.map(person => new Person(person))
        );
    }

    /**
     * `GET /api/inputs/people/{personId}`
     *
     * Get Person By Id
     */
    public async getById(id: string): Promise<Person> {
        return super.handleGetById(id).then(
            (response: BaseResponse) => new Person(response.data.person)
        );
    }

    /**
     * `GET /api/inputs/people/{personId}/card`
     *
     * Get Person Card
     */
    public async getCard(id: string): Promise<any> {
        return this.http.get(this.buildUrl(`/${id}/card`)).toPromise();
    }

    /**
     * `GET /api/inputs/people/realtors`
     *
     * Get Realtors
     */
    public getRealtors(withSalesTeams: boolean = false): Promise<Realtor[]> {
        return super.handleBaseResponse(
            this.http.get(
                this.buildUrl(`/realtors?with_sales_teams=${withSalesTeams ? 1 : 0}`)
            ).toPromise()
        ).then(
            (response: BaseResponse) => response.data.realtors.map(
                (realtor) => new Realtor(realtor)
            )
        );
    }

    /**
     * `GET /api/inputs/people/managers`
     *
     * Get Managers
     */
    public getManagers(): Promise<Manager[]> {
        return super.handleBaseResponse(
            this.http.get(this.buildUrl('/managers')).toPromise()
        ).then(
            (response: BaseResponse) => response.data.managers.map(
                (manager) => new Manager(manager)
            )
        );
    }

    /**
     * `GET /api/inputs/people/regional-managers`
     *
     * Get RegionalManagers
     */
    public getRegionalManagers(): Promise<RegionalManager[]> {
        return super.handleBaseResponse(
            this.http.get(this.buildUrl('/regional-managers')).toPromise()
        ).then(
            (response: BaseResponse) => response.data.regionalManagers.map(
                (regionalManager) => new RegionalManager(regionalManager)
            )
        );
    }

    public get managersWithActiveSalesTeams() {
        return this.http.get(this.buildUrl('/managers/sales-teams/active')).toPromise().then(
            (response: BaseResponse) => response.data.managers.map(
                (manager) => new Manager(manager)
            )
        )
    }

    /**
     * `POST /api/inputs/people`
     *
     * Store Person
     */
    public async store(person: Person): Promise<Person> {
        return super.handleStore(person).then(
            (response: BaseResponse) => new Person(response.data.person)
        );
    }

    /**
     * `PUT /api/inputs/people/{personId}`
     *
     * Update Person
     */
    public async update(person: Person): Promise<Person> {
        return super.handleUpdate(person).then(
            (response: BaseResponse) => new Person(response.data.person)
        );
    }
}
