import { Serializable } from 'core/shared/serializable.model';
import { CommissionRule } from 'pages/automations/commission-payment-automation/shared/comission-rule.model';
import { CommissionInstallmentTransferStage } from 'pages/automations/commission-payment-automation/shared/commission-installment-transfer-stage.model';
import { ProductBlock } from 'pages/inputs/products/shared/product-block.model';
import { ProductUnit } from 'pages/inputs/products/shared/product-unit.model';

export class ProductStage extends Serializable {

    public id: string = null;
    public name: string = null;
    public external_id: string = null;
    public productBlocks: Array<ProductBlock> = [];
    public upfrontMaxInstallments: number = 0;
    public commissionRule: CommissionRule = null;
    public installmentTransferStages: CommissionInstallmentTransferStage[] = [];
    public active: boolean = null;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    get relationships() {
        return {
            productBlocks: ProductBlock,
            commissionRule: CommissionRule,
            installmentTransferStages: CommissionInstallmentTransferStage
        };
    }

    public get http_data() {
        return {
            id:                     this.id,
            upfrontMaxInstallments: this.upfrontMaxInstallments
        };
    }

    public get commissionHttpData() {
        return {
            id:               this.id,
            installmentTransferStages: this.installmentTransferStages.map((stage) => stage.httpData),
            commissionRuleId: this.commissionRule?.id,
            active:           this.active
        };
    }

    public get units(): ProductUnit[] {
        const units = this.productBlocks.map(
            (productBlock: ProductBlock) => productBlock.productUnits
        );

        return [].concat.apply([], units);
    }

    public get availableProductUnits(): ProductUnit[] {
        const units = this.productBlocks.map(
            (productBlock: ProductBlock) => productBlock.availableProductUnits
        );

        return [].concat.apply([], units);
    }

    public get availableProductUnitsCount(): number {
        return this.availableProductUnits.length;
    }

    public hasNoInstallmentTransferStages(installmentNumber: number) {
        return !this.installmentTransferStagesFrom(installmentNumber).length;
    }

    public installmentTransferStagesFrom(installmentNumber: number) {
        return this.installmentTransferStages.filter(
            (installmentTransferStage) => installmentTransferStage.installmentNumber === installmentNumber
        );
    }

    public addInstallmentTransferStage(installmentNumber: number) {
        this.installmentTransferStages.push(new CommissionInstallmentTransferStage({
            installmentNumber: installmentNumber
        }));
    }

    public removeInstallmentTransferStage(index: number) {
        this.installmentTransferStages.splice(index, 1);
    }
}
