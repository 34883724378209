import { Serializable } from "core/shared/serializable.model";
import { Approvable } from "pages/approvals/approval-flows/shared/approvable.interface";
import { ApprovalFlowApprover } from "pages/approvals/approval-flows/shared/approval-flow-approver.model";

export class ApprovalFlow extends Serializable {

    public name: string = null;
    public approvableType: string = null;
    public approvableField: string = null;
    public valueField: string = null;
    public orderedApproval: boolean = null;
    public enableNotifications: boolean = null;
    public approvers: ApprovalFlowApprover[] = [];

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return {
            approvers: ApprovalFlowApprover
        };
    }

    public addApprover(approver?: ApprovalFlowApprover) {
        const order = this.approvers.length;

        if (approver) {
            approver.order = order;
        } else {
            approver = new ApprovalFlowApprover({
                order:        order,
                minimumValue: 0
            });
        }

        this.approvers.push(approver);
    }

    public hasApprover(approver: ApprovalFlowApprover) {
        return this.approvers.some(
            (flowApprover: ApprovalFlowApprover) => flowApprover.order === approver.order
        );
    }

    public removeApprover(approver: ApprovalFlowApprover) {
        this.approvers.splice(approver.order, 1);
        this.recountApprovers();
    }

    public recountApprovers() {
        this.approvers.forEach(
            (approver: ApprovalFlowApprover, index) => approver.order = index
        );
    }

    public get approvableTypeName() {
        switch(this.approvableType) {
            case 'asset_transfer_item_groups':
                return 'Transferências de Ativo';
        }

        return '';
    }

    public get approvableFieldName() {
        switch(this.approvableField) {
            case 'target_cost_center_id':
                return 'Centro de Custo de Destino';
        }

        return '';
    }

    public get valueFieldName() {
        switch(this.valueField) {
            case 'residual_value':
                return 'Valor Residual';
        }

        return '';
    }

    public get approversHttpData() {
        return {
            id:             this.id,
            approvableType: this.approvableType,
            approvers: this.approvers.map(
                (approver: ApprovalFlowApprover) => approver.http_data
            )
        };
    }

    public approverIsRequired(approver: ApprovalFlowApprover, approvable: Approvable): boolean {
        let value = approvable[this.valueField];
        
        if (value === undefined) {
            return false;
        }

        return value >= approver.minimumValue;
    }
}