import { Serializable } from "core/shared/serializable.model";

export class PaymentMethod extends Serializable {

    public name: string = null;
    
    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }
}