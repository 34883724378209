import { Asset } from "pages/finances/asset-management/assets/shared/asset.model";
import { FixedAsset } from "pages/finances/asset-management/assets/shared/fixed-asset.model";
import { GradualAsset } from "pages/finances/asset-management/assets/shared/gradual-asset.model";
import { MovableAsset } from "pages/finances/asset-management/assets/shared/movable-asset.model";

export class AssetFactory {

    private input: any;
    
    constructor(input: any) {
        this.input = input;
    }

    private guessAssetClass() {
        switch(this.input?.assetCategory?.name) {
            case 'Bem Móvel':
                return MovableAsset;
            case 'Bem Imóvel':
                return FixedAsset;
            case 'Bem de Compra Gradual / CAPEX':
                return GradualAsset;
            default:
                return Asset;
        }
    }

    public create() {
        let assetClass = this.guessAssetClass();

        return new assetClass(this.input);
    }
}