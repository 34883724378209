import { Injectable } from '@angular/core';
import { BaseService } from 'core/shared/base.service';
import { BaseResponse } from 'core/shared/base-response.interface';
import { User } from 'pages/account/users/shared/user.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { CompanyArea } from 'pages/inputs/company-areas/shared/company-area.model';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseService {

    protected url: string = 'api/users';

    /**
     * `GET /api/users`
     *
     * Get Users
     */
    public async get(queryParams: string = ''): Promise<User[]> {
        return super.handleGet(queryParams).then(
            (response: BaseResponse) => response.data.users.map(user => new User(user))
        );
    }

    /**
     * `GET /api/users/{userId}`
     *
     * Get User By Id
     */
    public async getById(id: string): Promise<User> {
        return super.handleGetById(id).then(
            (response: BaseResponse) => new User(response.data.user)
        );
    }

    /**
     * `GET /api/users/me`
     *
     * Get Authenticated User
     */
    public async me(): Promise<User> {
        return super.handleBaseResponse(
            this.http.get(this.buildUrl('/me')).toPromise()
        ).then(
            (response: BaseResponse) => new User(response.data.user)
        );
    }

    /**
     * `POST /api/users`
     *
     * Store User
     */
    public async store(user: User): Promise<User> {
        return super.handleStore(user).then(
            (response: BaseResponse) => new User(response.data.user)
        );
    }

    /**
     * `POST /api/users/import`
     *
     * Import User From Ad
     */
    public async import(email: string): Promise<User> {
        return this.handleBaseResponse(
            this.http.post(this.buildUrl('/import'), {
                email: email
            }).toPromise()
        ).then(
            (response: BaseResponse) => new User(response.data.user)
        );
    }

    /**
     * `PUT /api/users/{userId}`
     *
     * Update User
     */
    public async update(user: User): Promise<User> {
        return super.handleUpdate(user).then(
            (response: BaseResponse) => new User(response.data.user)
        );
    }

    /**
     * `PUT /api/users/{userId}/toggle-email-notifications`
     *
     * Toggle Email Notifications
     */
    public async toggleEmailNotifications(): Promise<boolean> {
        return super.handleBaseResponse(
            this.http.put(
                this.buildUrl(`/me/toggle-email-notifications`), {}
            ).toPromise()
        ).then(
            (response: BaseResponse) => response.data.enableEmailNotifications
        );
    }

    /**
     * `PUT /api/users/me/update-company-areas`
     *
     * Toggle Email Notifications
     */
    public async updateCompanyAreas(user: User): Promise<CompanyArea[]> {
        return super.handleBaseResponse(
            this.http.put(
                this.buildUrl(`/me/update-company-areas`), {
                    companyAreas: user.companyAreas
                }
            ).toPromise()
        ).then(
            (response: BaseResponse) => response.data.companyAreas
        );
    }

    /**
     * `DELETE /api/users/{userId}`
     *
     * Delete User
     */
    public async delete(user: User): Promise<User> {
        return super.handleDelete(user).then(
            (response: BaseResponse) => new User(response.data.user)
        );
    }

        /**
     * `GET /api/users/oauth2/{userId}`
     *
     * Generate Token
     */
    public async generateToken(user: User) {
        return this.handleBaseResponse(
            this.http.get(this.buildUrl('/oauth2/' + user.id)
            ).toPromise()
        );
    }

        //Using any
        public editDataDetails: any = [];

        public subject = new Subject<any>();

        private messageSource = new  BehaviorSubject(this.editDataDetails);

        currentMessage = this.messageSource.asObservable();

        changeMessage(message: string) {
            this.messageSource.next(message);
        }
}
