import { Serializable } from 'core/shared/serializable.model';
import { SalesFunnelStage } from 'pages/pv/sales-funnels/shared/sales-funnel-stage.model';


export class ExternalStage extends Serializable {

    public id: string = null;
    public name: string = null;
    public workflowType: string = null;
    public externalId: number = null;
    public salesFunnelStage: SalesFunnelStage = null;
    public ignored: boolean = null;
    public conversionProbability: number = null;
    public order: number = null;

    constructor(data: Object = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return {
            salesFunnelStage: SalesFunnelStage
        };
    }

    get http_data(): Object {
        return {
            id: this.id,
            name: this.name,
            externalId: this.externalId,
            salesFunnelStage: this.salesFunnelStage,
            conversionProbability: this.conversionProbability,
            order: this.order
        };
    }
}
