import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
 
@Injectable()
export class LocalDateAdapter extends NgbDateAdapter<string> {
    readonly DELIMITER = '-';
 
    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            const isoDate = value.match(/^\d{4}-\d{2}-\d{2}T/);
            if (isoDate) {
                const date = new Date(value);
                return {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate(),
                };
            }
 
            const [year, month, day] = value.split(this.DELIMITER).map(Number);
            return { year, month, day };
        }
        return null;
    }
 
    toModel(date: NgbDateStruct | null): string | null {
        return date
            ? `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day
                  .toString()
                  .padStart(2, '0')}`
            : null;
    }
}