import { Serializable } from "core/shared/serializable.model";
import { User } from "pages/account/users/shared/user.model";
import { CostCenter } from "pages/inputs/cost-centers/shared/cost-center.model";

export class CompanyArea extends Serializable {

    public id: string = null;
    public name: string = null;
    public description: string = null;
    public alwaysCreatePaymentForecast: boolean = false;
    public manager: User = null;
    public members: User[] = [];

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return {
            costCenter: CostCenter,
            companyArea: CompanyArea,
            manager: User
        }
    }

    public get http_data() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            managerId: this.manager?.id,
            alwaysCreatePaymentForecast: this.alwaysCreatePaymentForecast
        }
    }

}
